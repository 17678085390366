import ellipse12 from "@images/Testimonials/mocked-testimonials/Ellipse 12.webp";
import ellipse121 from "@images/Testimonials/mocked-testimonials/Ellipse 12-1.webp";
import ellipse122 from "@images/Testimonials/mocked-testimonials/Ellipse 12-2.webp";
import ellipse124 from "@images/Testimonials/mocked-testimonials/Ellipse 12-4.webp";
import ellipse125 from "@images/Testimonials/mocked-testimonials/Ellipse 12-5.webp";
import ellipse127 from "@images/Testimonials/mocked-testimonials/Ellipse 12-7.webp";
import ellipse128 from "@images/Testimonials/mocked-testimonials/Ellipse 12-8.webp";
import ellipse129 from "@images/Testimonials/mocked-testimonials/Ellipse 12-9.webp";
import rectangle43 from "@images/Testimonials/mocked-testimonials/Rectangle 43.webp";
import rectangle431 from "@images/Testimonials/mocked-testimonials/Rectangle 43-1.webp";
import rectangle432 from "@images/Testimonials/mocked-testimonials/Rectangle 43-2.webp";
import rectangle433 from "@images/Testimonials/mocked-testimonials/Rectangle 43-3.webp";

import { CardInterface } from "@pages/Testimonials/Cards/Card";

const mockedTestimonials: CardInterface[] = [
  {
    person: {
      name: "Jessica Doe",
      position: "Manager, Company Name",
      testimonial:
        "Praesent volutpat diam iaculis, fringilla orci vitae, hendrerit odio. Aenean venenatis, mauris et suscipit venenatis, augue lectus gravida dui, eget commodo mauris ex non risus. Vestibulum ultricies congue leo, quis pulvinar mi porttitor et.",
    },
    media: {
      type: "image",
      content: {
        src: ellipse12,
      },
    },
  },
  {
    person: {
      name: "Jessica Doe",
      position: "Manager, Company Name",
      testimonial:
        "Praesent volutpat diam iaculis, fringilla orci vitae, hendrerit odio. Aenean venenatis, mauris et suscipit venenatis, augue lectus gravida dui, eget commodo mauris ex non risus. Vestibulum ultricies congue leo, quis pulvinar mi porttitor et.",
    },
    media: {
      type: "video",
      content: {
        src: rectangle43,
        thumbnail: rectangle431,
      },
    },
  },
  {
    person: {
      name: "John Doe",
      position: "Manager, Company Name",
      testimonial:
        "Praesent volutpat diam iaculis, fringilla orci vitae, hendrerit odio. Aenean venenatis, mauris et suscipit venenatis, augue lectus gravida dui, eget commodo mauris ex non risus. Vestibulum ultricies congue leo, quis pulvinar mi porttitor et.",
    },
    media: {
      type: "image",
      content: {
        src: ellipse121,
      },
    },
  },
  {
    person: {
      name: "David Doe",
      position: "Manager, Company Name",
      testimonial:
        "Praesent volutpat diam iaculis, fringilla orci vitae, hendrerit odio. Aenean venenatis, mauris et suscipit venenatis, augue lectus gravida dui, eget commodo mauris ex non risus. Vestibulum ultricies congue leo, quis pulvinar mi porttitor et.",
    },
    media: {
      type: "image",
      content: {
        src: ellipse122,
      },
    },
  },
  {
    person: {
      name: "John Doe",
      position: "Manager, Company Name",
      testimonial:
        "Praesent volutpat diam iaculis, fringilla orci vitae, hendrerit odio. Aenean venenatis, mauris et suscipit venenatis, augue lectus gravida dui, eget commodo mauris ex non risus. Vestibulum ultricies congue leo, quis pulvinar mi porttitor et.",
    },
    media: {
      type: "image",
      content: {
        src: ellipse125,
      },
    },
  },
  {
    person: {
      name: "Jessica Doe",
      position: "Manager, Company Name",
      testimonial:
        "Praesent volutpat diam iaculis, fringilla orci vitae, hendrerit odio. Aenean venenatis, mauris et suscipit venenatis, augue lectus gravida dui, eget commodo mauris ex non risus. Vestibulum ultricies congue leo, quis pulvinar mi porttitor et.",
    },
    media: {
      type: "image",
      content: {
        src: ellipse129,
      },
    },
  },
  {
    person: {
      name: "Jessica Doe",
      position: "Manager, Company Name",
      testimonial:
        "Praesent volutpat diam iaculis, fringilla orci vitae, hendrerit odio. Aenean venenatis, mauris et suscipit venenatis, augue lectus gravida dui, eget commodo mauris ex non risus. Vestibulum ultricies congue leo, quis pulvinar mi porttitor et.",
    },
    media: {
      type: "video",
      content: {
        src: rectangle431,
        thumbnail: rectangle431,
      },
    },
  },
  {
    person: {
      name: "David Doe",
      position: "Manager, Company Name",
      testimonial:
        "Praesent volutpat diam iaculis, fringilla orci vitae, hendrerit odio. Aenean venenatis, mauris et suscipit venenatis, augue lectus gravida dui, eget commodo mauris ex non risus. Vestibulum ultricies congue leo, quis pulvinar mi porttitor et.",
    },
    media: {
      type: "video",
      content: {
        src: rectangle432,
        thumbnail: rectangle432,
      },
    },
  },
  {
    person: {
      name: "John Doe",
      position: "Manager, Company Name",
      testimonial:
        "Praesent volutpat diam iaculis, fringilla orci vitae, hendrerit odio. Aenean venenatis, mauris et suscipit venenatis, augue lectus gravida dui, eget commodo mauris ex non risus. Vestibulum ultricies congue leo, quis pulvinar mi porttitor et.",
    },
    media: {
      type: "image",
      content: {
        src: ellipse128,
      },
    },
  },
  {
    person: {
      name: "David Doe",
      position: "Manager, Company Name",
      testimonial:
        "Praesent volutpat diam iaculis, fringilla orci vitae, hendrerit odio. Aenean venenatis, mauris et suscipit venenatis, augue lectus gravida dui, eget commodo mauris ex non risus. Vestibulum ultricies congue leo, quis pulvinar mi porttitor et.",
    },
    media: {
      type: "image",
      content: {
        src: ellipse124,
      },
    },
  },
  {
    person: {
      name: "John Doe",
      position: "Manager, Company Name",
      testimonial:
        "Praesent volutpat diam iaculis, fringilla orci vitae, hendrerit odio. Aenean venenatis, mauris et suscipit venenatis, augue lectus gravida dui, eget commodo mauris ex non risus. Vestibulum ultricies congue leo, quis pulvinar mi porttitor et.",
    },
    media: {
      type: "image",
      content: {
        src: ellipse127,
      },
    },
  },
  {
    person: {
      name: "David Doe",
      position: "Manager, Company Name",
      testimonial:
        "Praesent volutpat diam iaculis, fringilla orci vitae, hendrerit odio. Aenean venenatis, mauris et suscipit venenatis, augue lectus gravida dui, eget commodo mauris ex non risus. Vestibulum ultricies congue leo, quis pulvinar mi porttitor et.",
    },
    media: {
      type: "video",
      content: {
        src: rectangle433,
        thumbnail: rectangle433,
      },
    },
  },
  {
    person: {
      name: "David Doe",
      position: "Manager, Company Name",
      testimonial:
        "Praesent volutpat diam iaculis, fringilla orci vitae, hendrerit odio. Aenean venenatis, mauris et suscipit venenatis, augue lectus gravida dui, eget commodo mauris ex non risus. Vestibulum ultricies congue leo, quis pulvinar mi porttitor et.",
    },
    media: {
      type: "image",
      content: {
        src: ellipse122,
      },
    },
  },
  {
    person: {
      name: "John Doe",
      position: "Manager, Company Name",
      testimonial:
        "Praesent volutpat diam iaculis, fringilla orci vitae, hendrerit odio. Aenean venenatis, mauris et suscipit venenatis, augue lectus gravida dui, eget commodo mauris ex non risus. Vestibulum ultricies congue leo, quis pulvinar mi porttitor et.",
    },
    media: {
      type: "image",
      content: {
        src: ellipse125,
      },
    },
  },
  {
    person: {
      name: "Jessica Doe",
      position: "Manager, Company Name",
      testimonial:
        "Praesent volutpat diam iaculis, fringilla orci vitae, hendrerit odio. Aenean venenatis, mauris et suscipit venenatis, augue lectus gravida dui, eget commodo mauris ex non risus. Vestibulum ultricies congue leo, quis pulvinar mi porttitor et.",
    },
    media: {
      type: "image",
      content: {
        src: ellipse129,
      },
    },
  },
];

export default mockedTestimonials;
