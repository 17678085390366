import React, { useRef, useState } from "react";

import PlayButton from "@components/PlayButton";
import ModalWindow from "@components/ModalWindow";
import useClickOutside from "@src/hooks/useClickOutside";
import VideoModal from "@src/components/VideoModal";

import quote from "@images/quote.svg";

import "./Card.scss";

type Image = {
  src: string;
};

type Video = {
  src: string;
  thumbnail: string;
};
interface Person {
  name: string;
  position: string;
  testimonial?: string;
}

export interface CardInterface {
  person: Person;
  media: {
    type: "image" | "video";
    content: Image | Video;
  };
}

const Card = ({ person, media }: CardInterface): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside({ ref, callback: () => setOpenModal(false) });

  return (
    <div className={`testimonials-card testimonials-card_${media.type}`}>
      <div className="testimonials-card__wrapper">
        <div className="testimonials-card__header">
          {media.type === "image" && (
            <>
              <img
                loading="lazy"
                src={quote}
                alt="“"
                className="testimonials-card__quote"
                title="quote medoxn"
                height="112"
                width="153"
              />
              <img
                src={quote}
                alt="“"
                title="quote medoxn"
                height="112"
                width="153"
                loading="lazy"
                className="testimonials-card__quote testimonials-card__quote_flipped"
              />
            </>
          )}
        </div>
        <div
          className={`testimonials-card__content testimonials-card__content_${media.type}`}
        >
          {media.type === "image" ? (
            <img
              loading="lazy"
              title={`${person.name} testimonials`}
              className={`testimonials-card__preview testimonials-card__preview_${media.type}`}
              src={media.content.src}
              alt={person.name}
              height="800"
              width="1200"
            />
          ) : (
            <div
              role="button"
              className={`testimonials-card__preview testimonials-card__preview_${media.type}`}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal(true);
              }}
              tabIndex={0}
              onKeyDown={({ key }) => {
                if (key === "Enter") {
                  setOpenModal(true);
                }
              }}
            >
              <img
                loading="lazy"
                title={`${person.name} testimonials`}
                src={media.content.src}
                alt={person.name}
                height="800"
                width="1200"
              />
              <PlayButton className="testimonials-card__button" />
            </div>
          )}
          <p className="testimonials-card__name">{person.name}</p>
          <p className="testimonials-card__position">{person.position}</p>
          {media.type === "image" && (
            <p className="testimonials-card__testimonials">
              {person.testimonial}
            </p>
          )}
        </div>
      </div>
      <ModalWindow open={openModal}>
        <div ref={ref}>
          <VideoModal
            closeModal={() => setOpenModal(false)}
            src={media.content.src}
          />
        </div>
      </ModalWindow>
    </div>
  );
};

export default Card;
