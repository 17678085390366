import React, { MutableRefObject, useState } from "react";
import {
  useKeenSlider,
  KeenSliderPlugin,
  KeenSliderInstance,
} from "keen-slider/react.es.js";

import Arrow from "@components/Arrow";

import useWindowDimensions from "@src/hooks/useWindowDimension";

import hall from "@images/Homepage/projects-gallery/hall.webp";
import dentalChair from "@images/Homepage/projects-gallery/dental-chair.webp";
import villageOrthodontics from "@images/Homepage/projects-gallery/village-orthodontics.webp";
import key from "@images/Homepage/projects-gallery/key.webp";
import pinebrook from "@images/Homepage/projects-gallery/pinebrook.webp";
import pattersonAndParsons from "@images/Homepage/projects-gallery/patterson-and-parsons.webp";
import hallThumbnail from "@images/Homepage/projects-gallery/hall-thumbnail.webp";
import dentalChairThumbnail from "@images/Homepage/projects-gallery/dental-chair-thumbnail.webp";
import villageOrthodonticsThumbnail from "@images/Homepage/projects-gallery/village-orthodontics-thumbnail.webp";
import keyThumbnail from "@images/Homepage/projects-gallery/key-thumbnail.webp";
import pinebrookThumbnail from "@images/Homepage/projects-gallery/pinebrook-thumbnail.webp";
import pattersonAndParsonsThumbnail from "@images/Homepage/projects-gallery/patterson-and-parsons-thumbnail.webp";

import "keen-slider/keen-slider.min.css";
import "./ProjectGallery.scss";

const clinicsGallery = [
  {
    alt: "clinic hall",
    src: hall,
    thumbnail: hallThumbnail,
    projectName: "Project name",
  },
  {
    alt: "dental chair",
    src: dentalChair,
    thumbnail: dentalChairThumbnail,
    projectName: "Project name",
  },
  {
    alt: "village orthodontics",
    src: villageOrthodontics,
    thumbnail: villageOrthodonticsThumbnail,
    projectName: "Project name",
  },
  {
    alt: "key clinic",
    src: key,
    thumbnail: keyThumbnail,
    projectName: "Project name",
  },
  {
    alt: "pinebrook clinic",
    src: pinebrook,
    thumbnail: pinebrookThumbnail,
    projectName: "Project name",
  },
  {
    alt: "patterson and parsons clinic",
    src: pattersonAndParsons,
    thumbnail: pattersonAndParsonsThumbnail,
    projectName: "Project name",
  },
];

const ThumbnailPlugin = (
  mainRef: MutableRefObject<KeenSliderInstance | null>
): KeenSliderPlugin => {
  return (slider) => {
    const removeActive = () => {
      slider.slides.forEach((slide) => {
        slide.classList.remove("project-gallery__active");
      });
    };
    const addActive = (idx: number) => {
      slider.slides[idx].classList.add("project-gallery__active");
    };

    const addClickEvents = () => {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx);
        });
      });
    };

    slider.on("created", () => {
      if (!mainRef.current) return;
      addActive(slider.track.details.rel);
      addClickEvents();
      mainRef.current.on("animationStarted", (main) => {
        removeActive();
        const next = main.animator.targetIdx || 0;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(next);
      });
    });
  };
};

const ProjectGallery = (): JSX.Element => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const { width } = useWindowDimensions();

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  const [thumbnailRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      slides: {
        perView: 6,
        spacing: 32,
      },
      breakpoints: {
        "(max-width: 1366px)": {
          slides: {
            perView: 6,
            spacing: 28,
          },
        },
        "(max-width: 992px)": {
          slides: {
            perView: 3.5,
            spacing: 22,
          },
        },
        "(max-width: 576px)": {
          slides: {
            perView: 2.2,
            spacing: 15,
          },
        },
      },
    },
    [ThumbnailPlugin(instanceRef)]
  );

  return (
    <section className="project-gallery">
      <div className="container">
        <h2 className="project-gallery__title">Projects gallery</h2>
        <div className="project-gallery__slider-container">
          <div className="project-gallery__slider">
            <div ref={sliderRef} className="keen-slider ">
              {clinicsGallery.map(({ alt, src, projectName }) => (
                <div
                  key={alt}
                  className="keen-slider__slide project-gallery__picture"
                >
                  <p className="project-gallery__project_name">{projectName}</p>
                  <img loading="lazy" alt={alt} src={src} title={alt} />
                </div>
              ))}
            </div>
            {instanceRef.current && loaded && (
              <div className="project-gallery__arrows">
                {width <= 992 && <p>Scroll to see more</p>}
                <Arrow
                  properties={{
                    direction: "left",
                    disabled: currentSlide === 0,
                  }}
                  onClick={() =>
                    instanceRef &&
                    instanceRef.current &&
                    instanceRef.current.prev()
                  }
                />
                <Arrow
                  properties={{
                    direction: "right",
                    disabled:
                      instanceRef &&
                      instanceRef.current &&
                      currentSlide ===
                        instanceRef.current.track.details.slides.length - 1,
                  }}
                  onClick={() =>
                    instanceRef &&
                    instanceRef.current &&
                    instanceRef.current.next()
                  }
                />
              </div>
            )}
          </div>
          <div
            ref={thumbnailRef}
            className="keen-slider project-gallery__thumbnail"
          >
            {clinicsGallery.map(({ alt, thumbnail }) => (
              <div
                key={alt}
                className="keen-slider__slide project-gallery__thumbnail_slide"
              >
                <img
                  loading="lazy"
                  alt={alt}
                  src={thumbnail}
                  title={alt}
                  height="130"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectGallery;
