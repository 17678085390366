import React, { useEffect } from "react";

import "./ModalWindow.scss";

interface Props {
  open: boolean;
  children: React.ReactNode;
  className?: string;
}

const ModalWindow = ({
  children,
  open,
  className,
}: Props): JSX.Element | null => {
  useEffect(() => {
    if (open) {
      document.ontouchmove = (e) => e.preventDefault();
      document.body.style.overflow = "hidden";
    } else {
      document.ontouchmove = () => true;
      document.body.style.overflow = "";
    }
  }, [open]);

  return open ? (
    <div className={`modal-window ${className}`}>{children}</div>
  ) : null;
};

ModalWindow.defaultProps = {
  className: "",
};

export default ModalWindow;
