import React from "react";

import "./AboutUsInfo.scss";
import AboutUsList from "./AboutUsList";

const AboutUsInfo = (): JSX.Element => (
  <section className="about-us__info info">
    <div className="container info__container">
      <div className="info__title-wrap">
        <h1 className="info__title">
          About us <span className="info__title_span">Medoxn</span>
        </h1>
      </div>
      <span className="info__span" />
      <AboutUsList />
    </div>
  </section>
);

export default AboutUsInfo;
