import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

import PlayButton from "@components/PlayButton";

import CloseButton from "@components/CloseButton";

import "./Video.scss";

interface Props {
  closeModal: () => void;
  src: string;
}

const VideoModal = ({ closeModal, src }: Props): JSX.Element => {
  const [showThumbnail, setShowThumbnail] = useState(true);

  const ref = useRef<ReactPlayer>(null);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.showPreview();
    }
  }, []);

  const windowResize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    window.addEventListener("resize", windowResize);
    return () => window.removeEventListener("resize", windowResize);
  }, []);

  return (
    <div
      role="presentation"
      onClick={(e) => e.stopPropagation()}
      className="video container"
    >
      <div className="video__thumbnail">
        {showThumbnail && (
          <img className="video__thumbnail_image" src={src} alt="thumbnail" />
        )}
        <ReactPlayer
          light="empty"
          onReady={() => {
            setShowThumbnail(false);
          }}
          ref={ref}
          controls
          width="100%"
          height="100%"
          playing
          url="https://www.youtube.com/watch?v=NpEaa2P7qZI"
          playIcon={<PlayButton className="video__play-button" />}
        />
        <CloseButton onClick={closeModal} className="video__close-btn" />
      </div>
    </div>
  );
};

export default VideoModal;
