import React, { useEffect, useState } from "react";
import { useKeenSlider } from "keen-slider/react.es.js";

import Arrow from "@components/Arrow";

import Association1 from "@images/AboutUs/association-1.svg";
import Association2 from "@images/AboutUs/association-2.svg";
import Association3 from "@images/AboutUs/association-3.svg";
import Association4 from "@images/AboutUs/association-4.svg";

import "./Associations.scss";

import useWindowDimensions from "@src/hooks/useWindowDimension";
import arrayFromNumber from "@src/utils/arrayFromNumber";

const Associations = (): JSX.Element => {
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slides: {
      perView: 4,
      spacing: 30,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    breakpoints: {
      "(max-width: 992px)": {
        slides: {
          perView: 2,
          spacing: 30,
        },
      },
      "(max-width: 576px)": {
        slides: {
          perView: 1.2,
          spacing: 20,
        },
      },
    },
  });
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (instanceRef.current) {
      const { perView } = instanceRef.current.options.slides as {
        perView: number;
      };
      setSlidesPerView(perView);
    }
  }, [width, instanceRef]);

  const slides = [
    {
      id: 0,
      img: Association1,
    },
    {
      id: 1,
      img: Association2,
    },
    {
      id: 2,
      img: Association3,
    },
    {
      id: 3,
      img: Association4,
    },
    {
      id: 4,
      img: Association3,
    },
    {
      id: 5,
      img: Association4,
    },
  ];

  return (
    <section className="about-us-associations associations">
      <div className="associations__container container">
        <h2 className="associations__title">Associations</h2>
        <div className="navigation-wrapper">
          <div ref={sliderRef} className="keen-slider">
            {slides.map((slide, number) => (
              <div
                className={`keen-slider__slide number-slide${number}`}
                key={slide.id}
              >
                <img
                  className="keen-slider__img"
                  src={slide.img}
                  alt="association"
                  title="association"
                  height="147"
                  width="360"
                  loading="lazy"
                />
              </div>
            ))}
          </div>
          <div className="associations__controls">
            {loaded && instanceRef && instanceRef.current && (
              <div className="dots">
                {arrayFromNumber(width <= 992 ? 5 : 3).map((slide) => (
                  <button
                    type="button"
                    key={slide}
                    onClick={() => {
                      instanceRef.current?.moveToIdx(slide);
                    }}
                    aria-label="button"
                    className={`dot  ${
                      currentSlide === slide ? " active" : ""
                    }`}
                  />
                ))}
              </div>
            )}
            {loaded && instanceRef.current && (
              <div className="associations__arrows">
                <Arrow
                  properties={{
                    direction: "left",
                    disabled: currentSlide === 0,
                  }}
                  onClick={() => instanceRef.current?.prev()}
                />
                <Arrow
                  properties={{
                    direction: "right",
                    disabled:
                      instanceRef &&
                      instanceRef.current &&
                      currentSlide ===
                        instanceRef.current.track.details.slides.length -
                          Math.floor(slidesPerView),
                  }}
                  onClick={() => instanceRef.current?.next()}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Associations;
