import React, { useRef, useState } from "react";

import Button from "@src/components/Button";

import Anon from "@images/Article/user-anon.png";
import User1 from "@images/Article/user-1.png";
import User2 from "@images/Article/user-2.png";
import User3 from "@images/Article/user-3.png";

import Like from "@images/Article/like.svg";
import { SendBtn } from "@src/images/Article/SocialImg";

import ModalWindow from "@src/components/ModalWindow";

import useClickOutside from "@src/hooks/useClickOutside";

import ArticleControls from "../ArticleHeader/ArticleControls";
import ArticleComment from "./ArticleComment";
import AdditionalComment from "./ArticleComment/AdditionalComment";
import Popup from "../Popup";

import "./ArticleForm.scss";

export type CommentType = {
  user: {
    image: string;
    name: string;
  };
  text: string;
  createdAt: string;
  likes: number;
  liked: boolean;
};

export type CommentInterface = {
  user: {
    image: string;
    name: string;
  };
  text: string;
  createdAt: string;
  likes: number;
  liked: boolean;
  comments: CommentType[];
};

const ArticleForm = (): JSX.Element => {
  const [showPopup, setShowPopup] = useState(false);
  const comments: CommentInterface[] = [
    {
      user: {
        image: User1,
        name: "Philip Levitt",
      },
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ",
      createdAt: "15 mins ago",
      likes: 0,
      liked: false,
      comments: [
        {
          user: {
            image: User2,
            name: "Mike Fegelman",
          },
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          createdAt: "26 mins ago",
          likes: 2,
          liked: true,
        },
      ],
    },
    {
      user: {
        image: User3,
        name: "Gary Cohen",
      },
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ",
      createdAt: "1 hour ago",
      likes: 1,
      liked: false,
      comments: [],
    },
  ];

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside({
    ref,
    callback: () => setShowPopup(false),
  });

  return (
    <section className="article-form">
      <div className="article-form__container container">
        <div className="article-form__header">
          <div className="article-form__control-wrap">
            <button
              type="button"
              className="article-form__controls article-form__controls_button"
            >
              <img src={Like} alt="like" title="like" width="18" height="18" />
              <span className="article-form__controls_span">80</span>
            </button>
            <p className="article-form__controls">
              Views <span className="article-form__controls_span">325</span>
            </p>
            <p className="article-form__controls">
              Comments <span className="article-form__controls_span">21</span>
            </p>
          </div>
          <div className="article-form__button-wrap">
            <ArticleControls />
          </div>
        </div>
        <div className="article-form__form-wrap">
          <form className="article-form__form">
            <a href="https://www.youtube.com/">
              <img
                src={Anon}
                alt="medoxn user comment"
                title="medoxn user comment"
                height="83"
                width="83"
              />
            </a>
            <div className="article-form__input-wrap">
              <textarea
                className="article-form__input"
                placeholder="Your comment..."
              />
              <button type="button" className="article-form__send-btn">
                <SendBtn />
              </button>
            </div>
          </form>
          <button
            className="button-read-more"
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              setShowPopup(!showPopup);
            }}
          >
            Sign in to join the conversation
          </button>
        </div>
        <div className="article-form__comment-wrap">
          {comments.map((comment) => (
            <div key={comment.createdAt}>
              <ArticleComment comment={comment} />
              {comment.comments.map((commentItem) => (
                <AdditionalComment
                  comment={commentItem}
                  key={commentItem.createdAt}
                />
              ))}
            </div>
          ))}
        </div>
        <Button type="button" onClick={() => null}>
          View more
        </Button>
      </div>
      <ModalWindow open={showPopup}>
        <div ref={ref}>
          <Popup onClick={() => setShowPopup(false)} />
        </div>
      </ModalWindow>
    </section>
  );
};

export default ArticleForm;
