import React, { useState } from "react";

import { useKeenSlider } from "keen-slider/react.es.js";

import Arrow from "@src/components/Arrow";
import SliderItemImg from "@images/Projects/hero-slider-item.webp";

import "./ProjectsHero.scss";

const ProjectsHero = (): JSX.Element => {
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slides: {
      perView: 1,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  const sliderQuantityArr = Array.from(Array(9).keys());

  return (
    <section className="projects-hero">
      <div className="hero-container">
        <div className="slider-wrapper">
          <div ref={sliderRef} className="keen-slider">
            {sliderQuantityArr.map((slide) => (
              <img
                key={slide}
                src={SliderItemImg}
                alt="slider-item"
                title="projects medoxn"
                className="keen-slider__slide"
                height="2731"
                width="4096"
              />
            ))}
          </div>
          {loaded && instanceRef.current && (
            <div className="dots">
              {sliderQuantityArr.map((slide) => (
                <button
                  type="button"
                  key={slide}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(slide);
                  }}
                  aria-label="button"
                  className={`dot  ${currentSlide === slide ? " active" : ""}`}
                />
              ))}
            </div>
          )}
        </div>
        <div className="projects-hero__controls container">
          <h1 className="projects-hero__controls-title">Our Projects</h1>
          {loaded && instanceRef.current && (
            <div className="projects-hero__arrows">
              <p>Scroll to see more</p>
              <Arrow
                properties={{
                  direction: "left",
                  disabled: currentSlide === 0,
                }}
                onClick={() => instanceRef.current?.prev()}
              />
              <Arrow
                properties={{
                  direction: "right",
                  disabled:
                    instanceRef &&
                    instanceRef.current &&
                    currentSlide ===
                      instanceRef.current.track.details.slides.length - 1,
                }}
                onClick={() => instanceRef.current?.next()}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ProjectsHero;
