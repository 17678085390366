import React from "react";

import Img1 from "@images/AboutUs/design.svg";
import Img2 from "@images/AboutUs/zoning.svg";
import Img3 from "@images/AboutUs/construct.svg";
import Img4 from "@images/AboutUs/alone.svg";
import Img5 from "@images/AboutUs/home.svg";
import Img6 from "@images/AboutUs/tooth.svg";

import "./CanHelp.scss";

const CanHelp = (): JSX.Element => {
  const itemList = [
    { id: 0, img: Img1, title: "Project design" },
    { id: 1, img: Img2, title: "Zoning & Variance approval" },
    { id: 2, img: Img3, title: "Construct your office" },
    { id: 3, img: Img4, title: "Don't do it alone" },
    { id: 4, img: Img5, title: "All in one stop shop" },
    {
      id: 5,
      img: Img6,
      title: "You focus on dental, we focus on construction",
    },
  ];

  return (
    <section className="about-help">
      <div className="about-help__container container">
        <h2 className="about-help__title">How we can help you</h2>
        <div className="about-help__list">
          {itemList.map((item) => (
            <div className="about-help__item" key={item.id}>
              <div className="about-help__item-wrap">
                <img
                  height={80}
                  width={80}
                  src={Img2}
                  alt={item.title}
                  title={item.title}
                  loading="lazy"
                />
                <h3>{item.title}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CanHelp;
