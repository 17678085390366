import React from "react";

export const Twitter = (): JSX.Element => (
  <svg width="20" height="17" viewBox="0 0 20 17">
    <path d="M20 1.92375C19.2563 2.25 18.4637 2.46625 17.6375 2.57125C18.4875 2.06375 19.1363 1.26625 19.4412 0.305C18.6488 0.7775 17.7738 1.11125 16.8412 1.2975C16.0887 0.49625 15.0162 0 13.8462 0C11.5763 0 9.74875 1.8425 9.74875 4.10125C9.74875 4.42625 9.77625 4.73875 9.84375 5.03625C6.435 4.87 3.41875 3.23625 1.3925 0.7475C1.03875 1.36125 0.83125 2.06375 0.83125 2.82C0.83125 4.24 1.5625 5.49875 2.6525 6.2275C1.99375 6.215 1.3475 6.02375 0.8 5.7225C0.8 5.735 0.8 5.75125 0.8 5.7675C0.8 7.76 2.22125 9.415 4.085 9.79625C3.75125 9.8875 3.3875 9.93125 3.01 9.93125C2.7475 9.93125 2.4825 9.91625 2.23375 9.86125C2.765 11.485 4.2725 12.6788 6.065 12.7175C4.67 13.8088 2.89875 14.4662 0.98125 14.4662C0.645 14.4662 0.3225 14.4513 0 14.41C1.81625 15.5813 3.96875 16.25 6.29 16.25C13.835 16.25 17.96 10 17.96 4.5825C17.96 4.40125 17.9538 4.22625 17.945 4.0525C18.7588 3.475 19.4425 2.75375 20 1.92375Z" />
  </svg>
);

export const Linkedin = (): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <path d="M18.5576 0H1.44241C0.645752 0 0 0.645752 0 1.44241V18.5576C0 19.3542 0.645752 20 1.44241 20H18.5576C19.3542 20 20 19.3542 20 18.5576V1.44241C20 0.645752 19.3542 0 18.5576 0V0ZM7.09412 15.1172H4.65866V7.79007H7.09412V15.1172ZM5.87646 6.78955H5.8606C5.04333 6.78955 4.51477 6.22696 4.51477 5.52383C4.51477 4.80484 5.05951 4.25781 5.89264 4.25781C6.72577 4.25781 7.23846 4.80484 7.25433 5.52383C7.25433 6.22696 6.72577 6.78955 5.87646 6.78955ZM15.8759 15.1172H13.4407V11.1974C13.4407 10.2122 13.0881 9.54041 12.2069 9.54041C11.5341 9.54041 11.1334 9.99359 10.9573 10.4311C10.8929 10.5876 10.8772 10.8064 10.8772 11.0254V15.1172H8.44193C8.44193 15.1172 8.47382 8.47748 8.44193 7.79007H10.8772V8.82751C11.2009 8.32825 11.7799 7.6181 13.0721 7.6181C14.6744 7.6181 15.8759 8.66531 15.8759 10.9158V15.1172Z" />
  </svg>
);

export const Facebook = (): JSX.Element => (
  <svg width="10" height="18" viewBox="0 0 10 18">
    <path d="M6.35767 17.987V9.93521H9.05923L9.46455 6.79636H6.35767V4.79266C6.35767 3.88418 6.60891 3.26506 7.91316 3.26506L9.5739 3.26437V0.456876C9.28669 0.419553 8.30083 0.333984 7.15339 0.333984C4.75735 0.333984 3.11698 1.7965 3.11698 4.48179V6.79636H0.407227V9.93521H3.11698V17.987H6.35767Z" />
  </svg>
);

export const FacebookFill = (): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <path d="M18.896 2.26231e-08H1.10303C0.493774 0.000366235 -0.000122048 0.494507 2.26231e-08 1.104V18.897C0.000366235 19.5062 0.494507 20.0001 1.104 20H10.6836V12.2656H8.08594V9.23828H10.6836V7.01038C10.6836 4.42676 12.2609 3.02051 14.5656 3.02051C15.6694 3.02051 16.618 3.10278 16.8945 3.13953V5.83984H15.3053C14.0514 5.83984 13.8086 6.43567 13.8086 7.31018V9.23828H16.8066L16.416 12.2656H13.8086V20H18.896C19.5056 20.0001 19.9999 19.5061 20 18.8965C20 18.8964 20 18.8962 20 18.896V1.10303C19.9998 0.493774 19.5055 -0.000122048 18.896 2.26231e-08Z" />
  </svg>
);

export const SaveBtn = (): JSX.Element => (
  <svg width="17" height="20" viewBox="0 0 17 20">
    <path d="M16.6625 0.661109C16.4906 0.414194 16.2591 0.233627 15.9685 0.119082C15.7834 0.0398638 15.5895 0.000185198 15.3867 0.000185198V0H1.5333C1.33064 0 1.13688 0.0396786 0.951777 0.118897C0.661017 0.233349 0.429612 0.414148 0.257795 0.660924C0.0860242 0.9077 0 1.18077 0 1.48047V18.5195C0 18.8193 0.0860242 19.0923 0.257795 19.3393C0.429612 19.586 0.661017 19.7668 0.951777 19.8812C1.1367 19.9604 1.33064 20 1.5333 20C1.94763 20 2.31316 19.8545 2.6305 19.564L8.46006 13.9589L14.2896 19.5641C14.5981 19.8456 14.9638 19.9867 15.3867 19.9867C15.6071 19.9867 15.801 19.9517 15.9684 19.8811C16.259 19.7667 16.4905 19.586 16.6624 19.3389C16.8342 19.0923 16.92 18.8192 16.92 18.5195V1.48065C16.9201 1.18096 16.834 0.907885 16.6625 0.661109ZM15.2281 18.1095L9.63657 12.7427L8.46006 11.6193L7.28364 12.7427L1.69215 18.1095V1.6922H15.2281V18.1095Z" />
  </svg>
);

export const BtnMore = (): JSX.Element => (
  <svg width="20" height="6" viewBox="0 0 20 6">
    <path d="M17.1891 1.83716e-08C15.6368 -0.000175577 14.3783 1.25843 14.3783 2.81092C14.3783 4.36324 15.6368 5.62167 17.1891 5.62167C18.7416 5.62167 20 4.36324 20 2.81092C19.9982 1.25913 18.7409 0.00175561 17.1891 1.83716e-08ZM17.1891 4.50105C16.2558 4.50105 15.4989 3.74421 15.4989 2.81092C15.4989 1.87746 16.2558 1.12079 17.1891 1.12079C18.1225 1.12079 18.8792 1.87728 18.8792 2.81092C18.8782 3.74386 18.122 4.49965 17.1891 4.50105Z" />
    <path d="M10.0002 0C8.44769 0 7.18909 1.25843 7.18909 2.81092C7.18909 4.36324 8.44769 5.62167 10.0002 5.62167C11.5525 5.62167 12.8109 4.36324 12.8109 2.81092C12.8109 1.25843 11.5525 0 10.0002 0ZM10.0002 4.50105C9.06654 4.50105 8.3097 3.74421 8.3097 2.81092C8.3097 1.87728 9.06654 1.12079 10.0002 1.12079C10.9335 1.12079 11.6901 1.87728 11.6901 2.81092C11.6901 3.74421 10.9335 4.50105 10.0002 4.50105Z" />
    <path d="M2.81092 0C1.25843 0 0 1.25843 0 2.81092C0 4.36324 1.25843 5.62167 2.81092 5.62167C4.36324 5.62167 5.62166 4.36324 5.62166 2.81092C5.61991 1.25913 4.36253 0.0017556 2.81092 0ZM2.81092 4.50105C1.87746 4.50105 1.12079 3.74421 1.12079 2.81092C1.12079 1.87728 1.87746 1.12079 2.81092 1.12079C3.74438 1.12079 4.50088 1.87728 4.50088 2.81092C4.50088 3.74421 3.74421 4.50105 2.81092 4.50105Z" />
  </svg>
);

export const Phone = (): JSX.Element => (
  <svg width="18" height="18" viewBox="0 0 18 18">
    <path d="M13.3056 0.333984H4.66196C2.2846 0.333984 0.333252 2.28534 0.333252 4.67643V13.3201C0.333252 15.6974 2.2846 17.6488 4.66196 17.6488H13.3056C15.6967 17.6488 17.6481 15.6974 17.6481 13.3064V4.67643C17.6481 2.28534 15.6967 0.333984 13.3056 0.333984ZM8.52343 3.89314C8.55092 3.46714 8.94943 3.6183 9.15556 3.59082C11.739 3.67327 13.9652 5.99565 13.9378 8.49668C13.9378 8.74404 14.0202 9.10133 13.6492 9.10133C13.2919 9.10133 13.3881 8.7303 13.3468 8.48294C13.0033 5.83075 11.7665 4.58024 9.07311 4.16798C8.85324 4.1405 8.50969 4.18172 8.52343 3.89314ZM12.3849 8.60662C11.9727 8.66159 12.0551 8.3043 12.0139 8.07068C11.739 6.44914 11.1619 5.85824 9.49911 5.50095C9.25176 5.44598 8.86698 5.4872 8.93569 5.11617C8.99066 4.75888 9.33421 4.88256 9.5953 4.91004C11.2581 5.10243 12.6185 6.51785 12.6048 8.07068C12.5773 8.23559 12.6872 8.55165 12.3849 8.60662ZM11.3543 7.75462C11.3543 7.97449 11.3268 8.18062 11.0794 8.2081C10.9008 8.23559 10.7909 8.08443 10.7634 7.90578C10.6947 7.24617 10.3374 6.84765 9.65027 6.73772C9.44414 6.71023 9.25176 6.64152 9.34795 6.36669C9.41666 6.18804 9.58156 6.16056 9.76021 6.16056C10.5023 6.14682 11.3543 7.01256 11.3543 7.75462ZM13.9652 12.8254C13.6767 13.6087 12.701 14.4057 11.8627 14.392C11.739 14.3645 11.5054 14.3233 11.2993 14.2408C7.61647 12.688 4.93679 10.132 3.42518 6.47662C2.91673 5.25359 3.45267 4.20921 4.73067 3.79695C4.96428 3.7145 5.18415 3.7145 5.41776 3.79695C5.96744 3.98933 7.35537 5.87198 7.38286 6.42165C7.41034 6.84765 7.10802 7.08127 6.81944 7.27365C6.25602 7.64468 6.25602 8.11191 6.50337 8.64784C7.03931 9.82965 7.96002 10.6542 9.15556 11.1764C9.58156 11.3687 10.0076 11.355 10.2961 10.9153C10.8183 10.132 11.4642 10.1732 12.165 10.6542C12.5223 10.9015 12.8796 11.1351 13.2094 11.3962C13.6629 11.7673 14.2401 12.0696 13.9652 12.8254Z" />
  </svg>
);

export const Skype = (): JSX.Element => (
  <svg width="22" height="22" viewBox="0 0 22 22">
    <path d="M20.7674 12.2898C20.8242 11.8637 20.8629 11.4318 20.8629 10.9908C20.8629 5.53913 16.4428 1.11904 10.9911 1.11904C10.5501 1.11904 10.1182 1.1577 9.69211 1.21446C8.75265 0.636141 7.6503 0.296387 6.4665 0.296387C3.05909 0.296387 0.296631 3.05884 0.296631 6.46626C0.296631 7.65005 0.636385 8.7524 1.21471 9.69187C1.15795 10.118 1.11928 10.5499 1.11928 10.9908C1.11928 16.4425 5.53938 20.8626 10.9911 20.8626C11.432 20.8626 11.8639 20.824 12.29 20.7672C13.2295 21.3455 14.3319 21.6853 15.5157 21.6853C18.9231 21.6853 21.6855 18.9228 21.6855 15.5154C21.6855 14.3316 21.3458 13.2293 20.7674 12.2898ZM11.1137 16.7494C7.90861 16.7494 6.06834 15.0695 6.06834 13.4209C6.06834 12.5802 6.94858 12.3112 7.30067 12.3112C8.11427 12.3112 8.38492 12.9751 8.533 13.2794C9.35976 14.979 10.1544 14.7553 10.9376 14.8293C11.6295 14.8943 13.2534 14.5891 13.2534 13.4028C13.2534 13.2893 13.4319 12.5177 12.0301 12.1351C11.1342 11.89 9.55473 11.5897 8.25494 11.0896C7.03249 10.619 6.31514 9.64991 6.31514 8.40113C6.31514 7.77674 6.63515 5.23229 10.9639 5.23229C13.8531 5.23229 15.3931 6.71223 15.3931 8.01449C15.3931 8.31393 15.2787 8.578 15.0492 8.81575C14.8197 9.05349 14.1731 9.40147 13.4648 8.94819C13.2863 8.83385 13.139 8.5961 12.963 8.28761C12.7507 7.90014 12.4036 7.15153 10.8052 7.15153C9.25446 7.15153 8.82422 7.89109 8.82422 8.19958C8.82422 9.23612 10.8027 9.45412 11.3341 9.59151C13.2739 10.0917 15.913 10.5515 15.913 13.1667C15.913 16.1694 12.7886 16.7494 11.1137 16.7494Z" />
  </svg>
);

export const WhatsUp = (): JSX.Element => (
  <svg width="19" height="19" viewBox="0 0 19 19">
    <path d="M9.49992 0.333984C4.43763 0.333984 0.333252 4.43836 0.333252 9.50065C0.333252 11.2203 0.816291 12.8236 1.64022 14.1989L0.41531 18.6673L4.97925 17.4693C6.31401 18.2281 7.85488 18.6673 9.49992 18.6673C14.5622 18.6673 18.6666 14.5629 18.6666 9.50065C18.6666 4.43836 14.5622 0.333984 9.49992 0.333984ZM6.36231 5.22466C6.51126 5.22466 6.66432 5.22376 6.79647 5.23063C6.95994 5.23445 7.13787 5.24642 7.30822 5.62302C7.51065 6.07066 7.95142 7.19364 8.00795 7.30746C8.06448 7.42128 8.10453 7.55536 8.02585 7.70432C7.95099 7.8571 7.91206 7.94956 7.80206 8.08477C7.68824 8.21616 7.56352 8.37935 7.4604 8.47865C7.34658 8.59247 7.22905 8.71727 7.36043 8.94415C7.49182 9.17102 7.94813 9.91478 8.62264 10.5152C9.48966 11.2898 10.2212 11.5278 10.4488 11.6416C10.6765 11.7555 10.8083 11.7377 10.9397 11.5849C11.0749 11.436 11.5075 10.9249 11.6603 10.6972C11.8093 10.4696 11.9613 10.509 12.1676 10.5838C12.3769 10.6587 13.4931 11.2085 13.7207 11.3223C13.9483 11.4362 14.0976 11.4925 14.1549 11.5849C14.2137 11.6804 14.2137 12.135 14.0251 12.6651C13.8364 13.1945 12.9099 13.7064 12.4943 13.7423C12.0749 13.7813 11.6835 13.9308 9.76847 13.1769C7.45848 12.2671 6.00168 9.9011 5.88786 9.74832C5.77404 9.59936 4.96284 8.51822 4.96284 7.40294C4.96284 6.28384 5.54959 5.73578 5.75507 5.50814C5.96438 5.2805 6.20953 5.22466 6.36231 5.22466Z" />
  </svg>
);

export const SendBtn = (): JSX.Element => (
  <svg width="26" height="26" viewBox="0 0 26 26">
    <path d="M25.1769 1.53997C25.0579 1.01215 24.4813 0.663404 23.9563 0.847154C23.9563 0.847154 1.42818 8.79809 1.41412 8.80465C1.04287 8.96122 0.778495 9.31653 0.800057 9.7309C0.81787 10.06 1.03162 10.3947 1.33349 10.5353L10.9672 15.0325C10.9672 15.0325 15.4504 24.6381 15.4794 24.6897C15.8807 25.4134 16.8857 25.315 17.1979 24.5818C17.1979 24.5818 25.1516 2.04622 25.1525 2.04247C25.2088 1.88403 25.2144 1.70684 25.1769 1.53997ZM16.1919 21.7947L12.7991 14.5262L15.6501 11.6753C16.0166 11.3087 16.0166 10.7162 15.6501 10.3497C15.2835 9.98309 14.691 9.98309 14.3244 10.3497L11.4735 13.2006L4.20412 9.80684L22.731 3.26778L16.1919 21.7947Z" />
  </svg>
);
