import CloseMenu from "@src/images/CloseMenu";
import React from "react";

import Google from "@images/Article/google.svg";
import Facebook from "@images/Article/facebook.svg";

import "./Popup.scss";

interface Props {
  onClick: () => void;
}

const Popup = ({ onClick }: Props): JSX.Element => (
  <div className="custom-popup">
    <div className="custom-popup__wrap">
      <h2>Sign In</h2>
      <button type="button" className="article-form__sign-btn">
        <img src={Google} alt="google" /> Continue with Google
      </button>
      <button type="button" className="article-form__sign-btn">
        <img src={Facebook} alt="google" />
        Continue with Facebook
      </button>
      <button
        className="custom-popup__close-btn"
        type="button"
        onClick={onClick}
      >
        <CloseMenu />
      </button>
    </div>
  </div>
);

export default Popup;
