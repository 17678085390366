import React, { useState, useEffect } from "react";

import { useKeenSlider } from "keen-slider/react.es.js";

import arrayFromNumber from "@src/utils/arrayFromNumber";
import useWindowDimensions from "@src/hooks/useWindowDimension";

import ProjectItem from "@src/pages/Projects/ProjectItem";
import Arrow from "@src/components/Arrow";

import "./OtherProjects.scss";

import Project1Img from "@images/Projects/project-items/project1.webp";
import Project2Img from "@images/Projects/project-items/project2.webp";
import Project3Img from "@images/Projects/project-items/project3.webp";
import Project4Img from "@images/Projects/project-items/project4.webp";

const OtherProjects = (): JSX.Element => {
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slides: {
      perView: 2,
      spacing: 30,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    breakpoints: {
      "(max-width: 978px)": {
        slides: {
          perView: 1,
          spacing: 0,
        },
      },
    },
  });

  const { width } = useWindowDimensions();
  useEffect(() => {
    if (instanceRef.current) {
      const { perView } = instanceRef.current.options.slides as {
        perView: number;
      };
      setSlidesPerView(perView);
    }
  }, [width, instanceRef]);

  const otherProjectsMock = [
    {
      id: 1,
      name: "Hutto Premiere Dentistry",
      image: Project1Img,
    },
    {
      id: 2,
      name: "4405 Dental Studio",
      image: Project2Img,
    },
    {
      id: 3,
      name: "Hutto Premiere Dentistry",
      image: Project3Img,
    },
    {
      id: 4,
      name: "4405 Dental Studio",
      image: Project4Img,
    },
  ];

  return (
    <div className="other-projects">
      <div className="container">
        <h2 className="other-projects__title">Other projects</h2>
        <div ref={sliderRef} className="keen-slider">
          {otherProjectsMock.map((project) => (
            <div key={project.id} className="keen-slider__slide">
              <ProjectItem
                key={project.id}
                id={project.id}
                name={project.name}
                image={project.image}
              />
            </div>
          ))}
        </div>
        {loaded && instanceRef.current && (
          <div className="other-projects__navigation">
            <div className="dots">
              {arrayFromNumber(width >= 978 ? 3 : 4).map((slide) => (
                <button
                  type="button"
                  key={slide}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(slide);
                  }}
                  aria-label="button"
                  className={`dot  ${currentSlide === slide ? " active" : ""}`}
                />
              ))}
            </div>
            <div className="other-projects__arrows">
              <Arrow
                properties={{
                  direction: "left",
                  disabled: currentSlide === 0,
                }}
                onClick={() => instanceRef.current?.prev()}
              />
              <Arrow
                properties={{
                  direction: "right",
                  disabled:
                    instanceRef &&
                    instanceRef.current &&
                    currentSlide ===
                      instanceRef.current.track.details.slides.length -
                        slidesPerView,
                }}
                onClick={() => instanceRef.current?.next()}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OtherProjects;
