import React from "react";

import Dentist from "@images/AboutUs/about-us-hero.webp";

import Request from "@components/Request";
import CanHelp from "./CanHelp";
import Endorsements from "./Endorsements";
import Media from "./Media";
import Associations from "./Associations";
import AboutUsInfo from "./AboutUsInfo";
import Article from "./Article";

import "./AboutUs.scss";

const AboutUs = (): JSX.Element => (
  <main className="about-us">
    <div className="hero-container">
      <img
        src={Dentist}
        alt="dentist"
        width="1920"
        height="496"
        title="dentist hospital client cabinet"
        className="about-us__first-img"
      />
    </div>
    <AboutUsInfo />
    <Article />
    <CanHelp />
    <Endorsements />
    <Associations />
    <Media />
    <Request />
  </main>
);

export default AboutUs;
