import React from "react";

import "./PlayButton.scss";

interface Props {
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
}

const PlayButton = ({ onClick, className }: Props): JSX.Element => (
  <button
    type="button"
    onClick={onClick}
    className={`play-button ${className}`}
  >
    <svg
      width="21"
      height="25"
      viewBox="0 0 21 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="play-button__icon"
    >
      <path d="M21 12.1042L0 0V24.2083" fill="#365EAC" />
    </svg>
  </button>
);

PlayButton.defaultProps = {
  onClick: () => null,
  className: "",
};

export default PlayButton;
