import React from "react";

import "./CloseButton.scss";

interface Props {
  onClick: () => void;
  className: string;
}

const CloseButton = ({ onClick, className }: Props): JSX.Element => (
  <button
    type="button"
    onClick={onClick}
    className={`close-button ${className}`}
  >
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.9688 7.24327L13.0554 2.15654C13.3864 1.82567 13.3864 1.29069 13.0554 0.959823C12.7245 0.628952 12.1895 0.628952 11.8587 0.959823L6.77193 6.04656L1.68534 0.959823C1.35432 0.628952 0.819497 0.628952 0.488626 0.959823C0.1576 1.29069 0.1576 1.82567 0.488626 2.15654L5.57521 7.24327L0.488626 12.33C0.1576 12.6609 0.1576 13.1959 0.488626 13.5267C0.653519 13.6918 0.870329 13.7747 1.08698 13.7747C1.30364 13.7747 1.52029 13.6918 1.68534 13.5267L6.77193 8.43999L11.8587 13.5267C12.0237 13.6918 12.2404 13.7747 12.457 13.7747C12.6737 13.7747 12.8903 13.6918 13.0554 13.5267C13.3864 13.1959 13.3864 12.6609 13.0554 12.33L7.9688 7.24327Z"
        fill="#365EAC"
      />
    </svg>
  </button>
);
export default CloseButton;
