import React from "react";
import Media1 from "@images/AboutUs/media.png";

const MediaItem = (): JSX.Element => (
  <div className="keen-slider__slide media-slide number-slide1">
    <div className="media-slide__header">
      <img
        src={Media1}
        alt="media"
        height="98"
        title="media medoxn"
        loading="lazy"
        width="290"
      />
    </div>
    <div className="media-slide__main">
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui neque fuga,
        mollitia repudiandae facere corporis voluptatibus. Quaerat, autem
        voluptatibus vitae, excepturi accusantium cumque, sit ullam consequuntur
        quidem porro non incidunt.
      </p>
      <button type="button" className="button-read-more">
        Read full news
      </button>
    </div>
  </div>
);
export default MediaItem;
