import React from "react";

import { FacebookFill, Linkedin, Twitter } from "@images/Article/SocialImg";

import "./ArticleControls.scss";

const ArticleControls = (): JSX.Element => (
  <>
    <a href="https://twitter.com/?lang=ru" className="article-control-link">
      <Twitter />
    </a>
    <a href="https://ru.linkedin.com" className="article-control-link">
      <Linkedin />
    </a>
    <a href="https://www.facebook.com/" className="article-control-link">
      <FacebookFill />
    </a>
  </>
);

export default ArticleControls;
