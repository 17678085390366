import React from "react";

import "./ProjectMain.scss";

import Main1Img from "@images/Project/main/main-1.webp";
import Main2Img from "@images/Project/main/main-2.webp";
import Main3Img from "@images/Project/main/main-3.webp";
import PrevProjectImg from "@images/Project/main/prev-project.webp";
import NextProjectImg from "@images/Project/main/next-project.webp";
import Arrow from "@src/components/Arrow";

const ProjectMain = (): JSX.Element => {
  const mainText = `Like many long-established dental practices, 1010 Dental in Worcester, 
    Massachusetts was originally built in a converted first floor of a two-family home.
     When Patricia Slinin, DMD, transitioned from staff to become the owner of the practice, 
     she knew the future demanded a new facility. Based on Dr. Slinin’s vision, we built a new 6,000 
     square foot facility, with 12 treatment areas, a sterilization and lab space, as well as office 
     and support space. The new practice suite has a modern décor, in a professional building with 
     ample parking. The project took six months and was delivered on budget. Moving into the new 
     space allowed the practice to add three additional providers and grow its patient population."`;

  return (
    <div className="project-main ">
      <div className="container">
        <p className="project-main__text">{mainText}</p>
        <img
          className="project-main__image1"
          loading="lazy"
          src={Main1Img}
          alt="project-1"
        />
        <p className="project-main__text">{mainText}</p>
        <div className="project-main__images-container">
          <img loading="lazy" src={Main2Img} alt="project-2" />
          <img loading="lazy" src={Main3Img} alt="project-3 " />
        </div>
        <p className="project-main__text">{mainText}</p>
      </div>
      <div className="project-main__navigation">
        <div className="project-main__navigation-button disabled">
          <img loading="lazy" src={PrevProjectImg} alt="prev-project" />
          <Arrow
            properties={{
              direction: "left",
              disabled: true,
            }}
          />
          <span>Prev project</span>
        </div>
        <div className="project-main__navigation-button">
          <span>Next project</span>
          <Arrow
            properties={{
              direction: "right",
              disabled: false,
            }}
          />
          <img loading="lazy" src={NextProjectImg} alt="next-project" />
        </div>
      </div>
    </div>
  );
};

export default ProjectMain;
