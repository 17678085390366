import React from "react";
import PostMain from "../ArticleMain";

import "./ArticleItem.scss";

interface Props {
  article: { id: number; image: string };
}

const ArticleItem = ({ article }: Props): JSX.Element => (
  <div className="article-item">
    <div className="article-item__wrap">
      <img
        loading="lazy"
        src={article.image}
        alt="article medoxn"
        title="article medoxn"
        className="article-item__img"
        height="307"
        width="490"
      />
      <PostMain id={article.id} />
    </div>
  </div>
);

export default ArticleItem;
