import React, { SetStateAction } from "react";

import { FormDataType } from "@src/pages/ContactUs";

export const validateEmail = (email: string): string => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!regex.test(email)) {
    return "Please enter your email address in format: yourname@example.com";
  }

  return "";
};

export const phoneMask = (phone: string): string =>
  phone
    .replace(/\D/g, "")
    .replace(/^(\d)/, "($1")
    .replace(/^(\(\d{3})(\d)/, "$1) $2")
    .replace(/(\d{3})(\d{1,5})/, "$1-$2")
    .replace(/(-\d{4})\d+?$/, "$1");

const validateForm = (
  object: FormDataType,
  setError: React.Dispatch<SetStateAction<Partial<FormDataType>>>
): void => {
  const errorObj: FormDataType = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  if (object.email.trim()) {
    errorObj.email = validateEmail(object.email);
  }

  Object.keys(object).forEach((key) => {
    if (object[key].trim() === "" && (key === "name" || key === "phone")) {
      errorObj[key] = "Please enter your data";
    }
  });

  setError(errorObj);
};

export default validateForm;
