import React from "react";

interface Props {
  className?: string;
  videoId: string;
}

const EmbedYoutubeVideo = ({ videoId, className }: Props): JSX.Element => (
  <div className={`${className}`}>
    <iframe
      width="620"
      height="379"
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      title="Embedded youtube"
    />
  </div>
);

EmbedYoutubeVideo.defaultProps = {
  className: "",
};

export default EmbedYoutubeVideo;
