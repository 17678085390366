import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Filter = "All reviews" | "Text only" | "Video only";

interface FilterState {
  filter: Filter;
}

const filter = "All reviews";
const initialState: FilterState = {
  filter,
};

const filterSlice = createSlice({
  name: "testimonials-filter",
  initialState,
  reducers: {
    changeFilter: (state, action: PayloadAction<Filter>) => {
      state.filter = action.payload;
    },
  },
});

export default filterSlice.reducer;

export const { changeFilter } = filterSlice.actions;
