import React from "react";

import { routes } from "@src/Routes";
import { NavLink } from "react-router-dom";

import "./NavList.scss";

const NavList = (): JSX.Element => (
  <nav className="nav">
    {routes.map((route) => (
      <NavLink
        to={route.path}
        key={route.path}
        className="nav-element"
        activeClassName="nav-element__active"
      >
        {route.name}
      </NavLink>
    ))}
  </nav>
);

export default NavList;
