import React, { useState } from "react";

import { useKeenSlider } from "keen-slider/react.es.js";

import Arrow from "@src/components/Arrow";
import SliderItemImg from "@images/Project/hero-slider-item.webp";
import locationImg from "@images/Project/icons/location.svg";
import SiteImg from "@images/Project/icons/site.svg";

import "./ProjectHero.scss";

const ProjectHero = (): JSX.Element => {
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slides: {
      perView: 1,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    breakpoints: {
      "(max-width: 768px)": {
        slides: {
          perView: 1,
        },
      },
    },
  });

  const sliderQuantityArr = Array.from(Array(9).keys());

  return (
    <section className="project-hero">
      <div className="hero-container">
        <div className="slider-wrapper">
          <div ref={sliderRef} className="keen-slider">
            {sliderQuantityArr.map((slide) => (
              <img
                key={slide}
                src={SliderItemImg}
                alt="slider-item"
                className="keen-slider__slide"
              />
            ))}
          </div>
          {loaded && instanceRef.current && (
            <div className="dots">
              {sliderQuantityArr.map((slide) => (
                <button
                  type="button"
                  key={slide}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(slide);
                  }}
                  aria-label="button"
                  className={`dot  ${currentSlide === slide ? " active" : ""}`}
                />
              ))}
            </div>
          )}
        </div>
        {loaded && instanceRef.current && (
          <div className="project-hero__controls container">
            <p>Scroll to see more</p>
            <div className="project-hero__arrows">
              <Arrow
                properties={{
                  direction: "left",
                  disabled: currentSlide === 0,
                }}
                onClick={() => instanceRef.current?.prev()}
              />
              <Arrow
                properties={{
                  direction: "right",
                  disabled:
                    instanceRef &&
                    instanceRef.current &&
                    currentSlide ===
                      instanceRef.current.track.details.slides.length - 1,
                }}
                onClick={() => instanceRef.current?.next()}
              />
            </div>
          </div>
        )}
        <div className="project-hero__info">
          <h1 className="project-hero__info-title">San Tan Family Dentistry</h1>
          <h2 className="project-hero__info-subtitle">Dr. Ryan Thomas, DDS</h2>
          <div className="project-hero__info-main">
            <div>
              <img src={locationImg} alt="location" />
              <span> Worcester, MA</span>
            </div>
            <div>
              <img src={SiteImg} alt="site" />
              <a href="https://santanfamilydentistry.com/">
                santanfamilydentistry.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectHero;
