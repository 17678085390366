import React from "react";

import { Filter, changeFilter } from "@src/store/Testimonials/filter";

import "./TestimonialsFilter.scss";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";

const filters: Filter[] = ["All reviews", "Text only", "Video only"];

const TestimonialsFilter = (): JSX.Element => {
  const { filter: currentFilter } = useAppSelector((state) => state.filter);
  const dispatch = useAppDispatch();

  const handleFilterChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(changeFilter(e.currentTarget.innerText as Filter));
  };

  return (
    <div className="testimonials-filter">
      {filters.map((filter) => (
        <button
          key={filter}
          className={`testimonials-filter__button ${
            currentFilter === filter
              ? "testimonials-filter__button_selected"
              : ""
          }`}
          type="button"
          onClick={handleFilterChange}
        >
          {filter}
        </button>
      ))}
    </div>
  );
};

export default TestimonialsFilter;
