import React from "react";

import Routes from "@src/Routes";

import Header from "@components/Header";
import Footer from "@components/Footer";

import "@stylesheets/style.scss";

const App = (): JSX.Element => (
  <div>
    <Header />
    <Routes />
    <Footer />
  </div>
);

export default App;
