import React from "react";

import Request from "@src/components/Request";
import Hero from "./Hero";
import ArticleHeader from "./ArticleHeader";
import ArticleMain from "./ArticleMain";
import ArticleForm from "./ArticleForm";
import OtherArticles from "./OtherArticles";

const Article = (): JSX.Element => (
  <main className="article">
    <Hero />
    <ArticleHeader />
    <ArticleMain />
    <ArticleForm />
    <OtherArticles />
    <Request />
  </main>
);

export default Article;
