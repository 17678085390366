import React, { useEffect, useState } from "react";

import useWindowDimensions from "@src/hooks/useWindowDimension";

import aboutUs from "@images/Homepage/about-us.webp";

import "./AboutUsSummary.scss";

const AboutUsSummary = (): JSX.Element => {
  const [showMore, setShowMore] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    setShowMore(width > 568);
  }, [width]);

  return (
    <section className="about-us-summary">
      <div className="about-us-summary__container container">
        <div className="about-us-summary__info">
          <div className="about-us-summary__text">
            <h2>ABOUT US</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
            {showMore && (
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.
              </p>
            )}
            <button
              type="button"
              className="about-us-summary__read-more"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "Read less" : "Read more"}
            </button>
          </div>
          <div className="about-us-summary__numbers">
            <div>
              <p>200+</p>
              <p>successful projects</p>
            </div>
            <div>
              <p>60+</p>
              <p>professionals in the team</p>
            </div>
            <div>
              <p>7+</p>
              <p>years of experience</p>
            </div>
          </div>
        </div>
        <div className="about-us-summary__photo">
          <img
            loading="lazy"
            title="Teambuilding working cooperation"
            src={aboutUs}
            alt="team"
          />
        </div>
      </div>
    </section>
  );
};

export default AboutUsSummary;
