import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import useClickOutside from "@src/hooks/useClickOutside";

import Logo from "@images/logo-wide.svg";
import Menu from "@images/menu.svg";
import NavList from "../NavList";

import CloseButton from "../CloseButton";

import "./Header.scss";

const Header = (): JSX.Element => {
  const [showMenu, setShowMenu] = useState(false);

  const handleClick = (e: React.MouseEvent) => {
    const element = e.target as HTMLElement;
    if (element.classList.contains("nav-element")) {
      setShowMenu(false);
    }
  };

  const ref = useRef(null);
  useClickOutside({ ref, callback: () => setShowMenu(false) });

  return (
    <header className="header">
      <div className="container">
        <div
          role="menu"
          className="header__wrap"
          onClick={handleClick}
          onKeyDown={() => null}
          tabIndex={0}
        >
          <div className="header__logo">
            <NavLink to="/">
              <img
                src={Logo}
                alt="logo"
                className="header-logo"
                title="medoxn logo"
                height="99"
                width="260"
              />
            </NavLink>
          </div>
          <button
            type="button"
            className="header__mobile-menu-btn"
            onClick={(e) => {
              e.stopPropagation();
              setShowMenu(!showMenu);
            }}
          >
            <img src={Menu} alt="menu" title="menu" height="12" width="22" />
          </button>
          <div
            ref={ref}
            className={`header__mobile-menu-list ${showMenu ? "active" : ""}`}
          >
            <CloseButton
              className="header__mobile-close"
              onClick={() => setShowMenu(false)}
            />
            <NavList />
          </div>
          <div className="header__nav">
            <NavList />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
