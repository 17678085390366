import React from "react";

import Logo from "@images/logo.svg";
import { Phone, WhatsUp, Facebook, Skype } from "@src/images/Article/SocialImg";

import { Link } from "react-router-dom";
import NavList from "../NavList";

import "./Footer.scss";

const Footer = (): JSX.Element => (
  <footer className="footer">
    <div className="footer__footer-nav footer-nav">
      <div className="footer__nav_container container">
        <div className="footer-nav__wrap">
          <div className="footer-nav__logo">
            <Link to="/">
              <img
                src={Logo}
                alt="logo"
                title="medoxn logo"
                height="87"
                width="100"
                loading="lazy"
              />
            </Link>
          </div>
          <div className="footer-nav__list">
            <NavList />
          </div>
        </div>
      </div>
    </div>
    <div className="footer-nav-mobile__list">
      <NavList />
    </div>
    <div className="footer__footer-info footer-info">
      <div className="footer-info__container container">
        <div className="footer-info_left">
          <div className="footer-info__content">
            <div className="footer-info__item">
              <h3 className="footer-info__title">Location</h3>
              <ul className="footer-info__list">
                <li>328 S Rockford Dr #3</li>
                <li>Tempe</li>
                <li>AZ 85281 </li>
                <li>United States</li>
              </ul>
            </div>
            <div className="footer-info__item">
              <h3 className="footer-info__title">Hours</h3>
              <ul className="footer-info__list">
                <li>Mon-Thurs 8am - 5pm</li>
                <li>Friday 8am - 12pm</li>
                <li>Weekends - Closed</li>
              </ul>
            </div>
            <div className="footer-info__item">
              <h3 className="footer-info__title">Contact</h3>
              <ul className="footer-info__list">
                <li>
                  P: <a href="tel:+18005953940">1-800-595-3940</a>
                </li>
                <li>
                  E: <a href="mailto:info@medoxn.com">info@medoxn.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-info_right">
          <div className="footer-info__media footer-media">
            <ul className="footer-media__list">
              <li>
                <a href="https://www.google.com.ua/">
                  <Phone />
                </a>
              </li>
              <li>
                <a href="https://www.google.com.ua/">
                  <WhatsUp />
                </a>
              </li>
              <li>
                <a href="https://www.google.com.ua/">
                  <Facebook />
                </a>
              </li>
              <li>
                <a href="https://www.google.com.ua/">
                  <Skype />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <p className="footer__copyright">
        Medoxn.com © 2021. All Rights Reserved.
      </p>
    </div>
  </footer>
);

export default Footer;
