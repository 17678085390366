import React from "react";

const AboutUsList = (): JSX.Element => (
  <div className="info__item-list">
    <div className="info__item">
      <div>
        <p>200+</p>
        <p>successful projects</p>
      </div>
      <div>
        <p>60+</p>
        <p>professionals in the team</p>
      </div>
      <div>
        <p>7+</p>
        <p>years of experience</p>
      </div>
    </div>
  </div>
);

export default AboutUsList;
