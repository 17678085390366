import React, { useState } from "react";

import Img from "@images/AboutUs/about-us-article.webp";
import Button from "@components/Button";

import "./Article.scss";

const Article = (): JSX.Element => {
  const [showText, setShowText] = useState(false);

  return (
    <section className="about-article">
      <div className="about-article__container container">
        <div className="about-article__content">
          <p className="about-article__text">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iste
            expedita officiis aspernatur deleniti vero a. Exercitationem optio
            illum perspiciatis ex tenetur quae corrupti quod minus. Consectetur
            incidunt veritatis mollitia cum.
          </p>
          <p className={`about-article__text ${showText ? "text-show" : ""}`}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Reprehenderit optio, iure ut dolorem architecto iusto minima quasi
            aliquid natus alias enim beatae tempore! Molestiae quibusdam laborum
            nemo a natus ex?
          </p>
          <p className={`about-article__text ${showText ? "text-show" : ""}`}>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sit
            inventore adipisci nemo temporibus libero ab, laudantium aliquid
            voluptatem. Eum fugiat error architecto impedit? Dicta recusandae
            reiciendis eaque tempora voluptate. Voluptatum.
          </p>
          <button
            type="button"
            className="about-article__read-more button-read-more"
            onClick={() => setShowText(!showText)}
          >
            {showText ? "Read less" : "Read more"}
          </button>
        </div>
        <div className="about-article__img">
          <img
            src={Img}
            alt="hospital"
            width="880"
            height="543"
            title="hospital"
            loading="lazy"
          />
          <Button type="button" onClick={() => null}>
            Order now!
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Article;
