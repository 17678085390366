import React from "react";

import TestimonialsFilter from "@pages/Testimonials/TestimonialsHero/TestimonialsFilter";

import "./TestimonialsHero.scss";

const TestimonialsHero = (): JSX.Element => (
  <section className="testimonials-hero">
    <div className="testimonials-hero__title">
      <h1>Testimonials</h1>
      <p>See what people say about our company.</p>
    </div>
    <TestimonialsFilter />
  </section>
);

export default TestimonialsHero;
