import React from "react";

import "./ProjectsMain.scss";

import Button from "@components/Button";

import Project1Img from "@images/Projects/project-items/project1.webp";
import Project2Img from "@images/Projects/project-items/project2.webp";
import Project3Img from "@images/Projects/project-items/project3.webp";
import Project4Img from "@images/Projects/project-items/project4.webp";
import Project5Img from "@images/Projects/project-items/project5.webp";
import Project6Img from "@images/Projects/project-items/project6.webp";
import Project7Img from "@images/Projects/project-items/project7.webp";
import Project8Img from "@images/Projects/project-items/project8.webp";
import Project9Img from "@images/Projects/project-items/project9.webp";
import Project10Img from "@images/Projects/project-items/project10.webp";
import Project11Img from "@images/Projects/project-items/project11.webp";
import Project12Img from "@images/Projects/project-items/project12.webp";

import ProjectItem from "../ProjectItem";

const ProjectsMain = (): JSX.Element => {
  const projectsMock = [
    {
      id: 1,
      name: "Hutto Premiere Dentistry",
      image: Project1Img,
    },
    {
      id: 2,
      name: "4405 Dental Studio",
      image: Project2Img,
    },
    {
      id: 3,
      name: "Hutto Premiere Dentistry",
      image: Project3Img,
    },
    {
      id: 4,
      name: "4405 Dental Studio",
      image: Project4Img,
    },
    {
      id: 5,
      name: "Hutto Premiere Dentistry",
      image: Project5Img,
    },
    {
      id: 6,
      name: "4405 Dental Studio",
      image: Project6Img,
    },
    {
      id: 7,
      name: "Hutto Premiere Dentistry",
      image: Project7Img,
    },
    {
      id: 8,
      name: "4405 Dental Studio",
      image: Project8Img,
    },
    {
      id: 9,
      name: "Hutto Premiere Dentistry",
      image: Project9Img,
    },
    {
      id: 10,
      name: "4405 Dental Studio",
      image: Project10Img,
    },
    {
      id: 11,
      name: "Hutto Premiere Dentistry",
      image: Project11Img,
    },
    {
      id: 12,
      name: "4405 Dental Studio",
      image: Project12Img,
    },
  ];

  return (
    <section className="projects-main">
      <div className="container">
        <div className="project-items">
          {projectsMock.map((project) => (
            <ProjectItem
              key={project.id}
              id={project.id}
              name={project.name}
              image={project.image}
            />
          ))}
        </div>
        <Button type="button" onClick={() => null}>
          More projects
        </Button>
      </div>
    </section>
  );
};

export default ProjectsMain;
