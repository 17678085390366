import React, { useEffect, useState } from "react";
import { useKeenSlider } from "keen-slider/react.es.js";

import EmbedYoutubeVideo from "@pages/Homepage/ClientsSay/EmbedYoutubeVideo";
import Arrow from "@components/Arrow";

import useWindowDimensions from "@src/hooks/useWindowDimension";

import quote from "@images/quote.svg";

import "./ClientsSay.scss";

const ClientsSay = (): JSX.Element => {
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(0);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    initial: 0,
    loop: false,
    drag: false,
    slides: {
      perView: 2.4,
    },
    breakpoints: {
      "(max-width: 1600px)": {
        slides: {
          perView: 1.8,
        },
      },
      "(max-width: 1200px)": {
        slides: {
          perView: 1.6,
        },
      },
      "(max-width: 1050px)": {
        slides: {
          perView: 1.5,
        },
      },
      "(max-width: 992px)": {
        slides: {
          perView: 1,
        },
      },
    },
  });

  const { width } = useWindowDimensions();
  useEffect(() => {
    if (instanceRef.current) {
      const { perView } = instanceRef.current.options.slides as {
        perView: number;
      };
      setSlidesPerView(perView);
    }
  }, [width, instanceRef]);

  return (
    <section className="clients-say">
      <div className="container">
        <div className="clients-say__info">
          <div className="clients-say__title">
            <h2>What our clients say</h2>
            <img
              className="clients-say__quotes"
              title="client feedback"
              src={quote}
              alt="“"
            />
          </div>
          <div>
            <p className="clients-say__text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
            {loaded && instanceRef.current && (
              <div className="clients-say__arrows">
                <Arrow
                  properties={{
                    direction: "left",
                    disabled: currentSlide === 0,
                  }}
                  onClick={() => instanceRef.current?.prev()}
                />
                <Arrow
                  properties={{
                    direction: "right",
                    disabled:
                      instanceRef &&
                      instanceRef.current &&
                      currentSlide ===
                        instanceRef.current.track.details.slides.length -
                          Math.floor(slidesPerView),
                  }}
                  onClick={() => instanceRef.current?.next()}
                />
              </div>
            )}
          </div>
        </div>
        <div ref={sliderRef} className="keen-slider clients-say__slider">
          <EmbedYoutubeVideo
            className="keen-slider__slide clients-say__slide"
            videoId="NpEaa2P7qZI"
          />
          <EmbedYoutubeVideo
            className="keen-slider__slide clients-say__slide"
            videoId="NpEaa2P7qZI"
          />
          <EmbedYoutubeVideo
            className="keen-slider__slide clients-say__slide"
            videoId="NpEaa2P7qZI"
          />
          <EmbedYoutubeVideo
            className="keen-slider__slide clients-say__slide"
            videoId="NpEaa2P7qZI"
          />
          <EmbedYoutubeVideo
            className="keen-slider__slide clients-say__slide"
            videoId="NpEaa2P7qZI"
          />
        </div>
      </div>
    </section>
  );
};

export default ClientsSay;
