import React from "react";

import Request from "@components/Request";

import ProjectsHero from "./ProjectsHero";
import ProjectsMain from "./ProjectsMain";

const Projects = (): JSX.Element => (
  <>
    <ProjectsHero />
    <ProjectsMain />
    <Request />
  </>
);

export default Projects;
