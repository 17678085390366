import React from "react";

import Request from "@components/Request";
import TestimonialsHero from "./TestimonialsHero";
import Cards from "./Cards";

import "./Testimonials.scss";

const Testimonials = (): JSX.Element => (
  <main className="testimonials__container">
    <TestimonialsHero />
    <Cards />
    <Request />
  </main>
);

export default Testimonials;
