import React from "react";
import ArticleControls from "./ArticleControls/index";

import "./ArticleHeader.scss";

const ArticleHeader = (): JSX.Element => (
  <section className="article-header">
    <div className="article-header__container">
      <p className="article-header__date">Oct 24, 2021</p>
      <div className="article-header__controls">
        <ArticleControls />
      </div>
    </div>
  </section>
);

export default ArticleHeader;
