import React from "react";

import { Proposition } from "@pages/Homepage/HowWeHelp";

import "./HelpCard.scss";

interface Props {
  proposition: Proposition;
}

const HelpCard = ({ proposition }: Props): JSX.Element => (
  <div className="help-card">
    <div className="help-card__title-container">
      <img
        className="help-card__icon"
        src={proposition.icon}
        title={proposition.title}
        alt={proposition.title}
      />
      <h3 className="help-card__title">{proposition.title}</h3>
    </div>
    <p className="help-card__text">{proposition.text}</p>
  </div>
);

export default HelpCard;
