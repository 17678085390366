import React, { useState } from "react";

import validateForm, { phoneMask } from "@src/utils/validateForm";

import Jessica from "@images/user-jessica.png";

import Button from "@src/components/Button";
import "./ContactUs.scss";

export const defaultFormData: FormDataType = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

export interface FormDataType {
  [key: string]: string;
  name: string;
  email: string;
  phone: string;
  message: string;
}

const ContactUs = (): JSX.Element => {
  const [formData, setFormData] = useState(defaultFormData);
  const [errors, setErrors] = useState<Partial<FormDataType>>({});

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    validateForm(formData, setErrors);
  };

  return (
    <section className="contact-us">
      <div className="contact-us__container container">
        <h1 className="contact-us__title">Contact us</h1>
        <div className="contact-us__row">
          <div className="contact-us__form-wrap">
            <form action="#" className="contact-us__form contact-form">
              <h4 className="contact-form__title">
                Please fill the following form to contact us!
              </h4>
              <label
                htmlFor="name"
                className={`contact-form__label ${
                  errors?.name ? "contact-form__label_error" : ""
                }`}
              >
                <div>
                  Name*
                  <span className="about-us-contact__error">
                    {errors?.name}
                  </span>
                </div>
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFormData((prev) => ({ ...prev, name: e.target.value }))
                  }
                  className="contact-form__name"
                  placeholder="Your Full Name"
                />
              </label>
              <label
                htmlFor="email"
                className={`contact-form__label ${
                  errors?.email ? "contact-form__label_error" : ""
                }`}
              >
                <div>
                  Email
                  <span className="about-us-contact__error">
                    {errors?.email}
                  </span>
                </div>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFormData((prev) => ({ ...prev, email: e.target.value }))
                  }
                  className="contact-form__email"
                  placeholder="e.g. example@gmail.com"
                />
              </label>
              <label
                htmlFor="phone"
                className={`contact-form__label ${
                  errors?.phone ? "contact-form__label_error" : ""
                }`}
              >
                <div>
                  Phone*
                  <span className="about-us-contact__error">
                    {errors?.phone}
                  </span>
                </div>
                <input
                  type="text"
                  id="phone"
                  value={formData.phone}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFormData((prev) => ({
                      ...prev,
                      phone: phoneMask(e.target.value),
                    }))
                  }
                  className="contact-form__phone"
                  placeholder="(000) 000-0000"
                />
              </label>
              <label
                htmlFor="message"
                className={`contact-form__label ${
                  errors?.message ? "contact-form__label_error" : ""
                }`}
              >
                <div>
                  Message
                  <span className="about-us-contact__error">
                    {errors?.message}
                  </span>
                </div>

                <textarea
                  id="message"
                  value={formData.message}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setFormData((prev) => ({
                      ...prev,
                      message: e.target.value,
                    }))
                  }
                  className="contact-form__message"
                  placeholder="What would you like to discuss?"
                />
              </label>
            </form>
            <Button type="submit" onClick={onSubmit}>
              Submit
            </Button>
          </div>
          <div className="contact-us__content">
            <ul>
              <li>
                <div>
                  <h4>Email us</h4>
                  <a href="mailto:info@medoxn.com">info@medoxn.com</a>
                </div>
              </li>
              <li>
                <div>
                  <h4>Call us</h4>
                  <a href="tel:18005953940">1-800-595-3940</a>
                </div>
              </li>
              <li>
                <div>
                  <img
                    src={Jessica}
                    alt="user"
                    title="user"
                    height="100"
                    width="100"
                    loading="lazy"
                  />
                  <h4>You can chat with Jessica right now!</h4>
                </div>
              </li>
            </ul>
            <button type="button" className="contact-us__chat-btn">
              Chat
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
