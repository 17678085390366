import React, { SetStateAction, useState } from "react";

import "./Select.scss";

interface Props {
  options: string[];
  value: string;
  setValue: React.Dispatch<SetStateAction<string>>;
}

const Select = ({ options, value, setValue }: Props): JSX.Element => {
  const [show, setShow] = useState(false);

  return (
    <div
      role="row"
      onKeyDown={({ key }) => {
        if (key === "Enter") {
          setShow(!show);
        }
      }}
      onClick={() => setShow(!show)}
      className="custom-select"
      tabIndex={0}
    >
      <div className={`custom-select__selected ${show ? "opened" : ""}`}>
        {value || "Please select"}
        <span
          className={`custom-select__span ${
            show ? "custom-select__span_opened" : ""
          }`}
        />
      </div>
      <ul
        className={`custom-select__wrap ${show ? "" : "custom-select__hide"}`}
      >
        {options.map((option) => (
          <li
            role="row"
            onKeyDown={() => null}
            key={option}
            className="custom-select__option"
            onClick={() => setValue(option)}
            onKeyPress={({ key }) => {
              if (key === "Enter") {
                setValue(option);
              }
            }}
            tabIndex={0}
          >
            {option}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Select;
