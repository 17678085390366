import React from "react";
import { Link } from "react-router-dom";

import "./ArticleMain.scss";

interface Props {
  id: number;
}

const ArticleMain = ({ id }: Props): JSX.Element => (
  <div className="post-article__content">
    <p className="post-article__date">Oct 24, 2021</p>
    <h3 className="post-article__title">Options For A Stuck Dentist</h3>
    <p className="post-article__text">
      Over the years, I have seen many dentists who are in business and
      struggling, more often than not as a result of their limited business
      background and/or struggling with personnel.
    </p>
    <Link to={`/blog/${id}`}>
      <button className="post-article__more-btn button-read-more" type="button">
        Read full article
      </button>
    </Link>
    <div className="post-article__footer">
      <div className="post-article__control-wrap">
        <p className="post-article__controls">
          Likes <span className="post-article__controls_span">80</span>
        </p>
        <p className="post-article__controls">
          Views <span className="post-article__controls_span">325</span>
        </p>
        <p className="post-article__controls">
          Comments <span className="post-article__controls_span">21</span>
        </p>
      </div>
    </div>
  </div>
);

export default ArticleMain;
