import React from "react";

import HelpCard from "@pages/Homepage/HowWeHelp/HelpCard";

import design from "@images/AboutUs/design.svg";
import zoning from "@images/AboutUs/zoning.svg";
import construct from "@images/AboutUs/construct.svg";
import alone from "@images/AboutUs/alone.svg";
import home from "@images/AboutUs/home.svg";
import tooth from "@images/AboutUs/tooth.svg";

import "./HowWeHelp.scss";

export interface Proposition {
  icon: string;
  title: string;
  text: string;
}

const propositionArray: Proposition[] = [
  {
    icon: design,
    title: "Project design",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    icon: zoning,
    title: "Zoning & Variance approval",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    icon: construct,
    title: "Construct your office",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    icon: alone,
    title: "Don’t do it alone",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    icon: home,
    title: "All in one stop shop",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    icon: tooth,
    title: "You focus on dental, we focus on construction",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const HowWeHelp = (): JSX.Element => (
  <section className="how-we-help">
    <div className="container">
      <h2 className="how-we-help__title">How we can help you</h2>
      <div className="how-we-help__proposal">
        {propositionArray.map((proposition) => (
          <HelpCard proposition={proposition} key={proposition.title} />
        ))}
      </div>
    </div>
  </section>
);

export default HowWeHelp;
