import React from "react";

import Button from "@src/components/Button";

import Article1 from "@images/Blog/article-1.webp";
import Article2 from "@images/Blog/article-2.webp";
import Article3 from "@images/Blog/article-3.webp";
import Article4 from "@images/Blog/article-4.webp";
import Article5 from "@images/Blog/article-5.webp";
import Article6 from "@images/Blog/article-6.webp";
import Article7 from "@images/Blog/article-7.webp";
import Article8 from "@images/Blog/article-8.webp";
import Article9 from "@images/Blog/article-9.webp";
import Article10 from "@images/Blog/article-10.webp";
import Article11 from "@images/Blog/article-11.webp";

import ArticleItem from "../ArticleItem";

import "./ArticleList.scss";

const ArticleList = (): JSX.Element => {
  const articleMock = [
    { id: 1, image: Article1 },
    { id: 2, image: Article2 },
    { id: 3, image: Article3 },
    { id: 4, image: Article4 },
    { id: 5, image: Article5 },
    { id: 6, image: Article6 },
    { id: 7, image: Article7 },
    { id: 8, image: Article8 },
    { id: 9, image: Article9 },
    { id: 10, image: Article10 },
    { id: 11, image: Article11 },
  ];

  return (
    <section className="blog__list blog-list">
      <div className="blog-list__container container">
        <div className="blog-list__row">
          {articleMock.map((article) => (
            <ArticleItem article={article} key={article.id} />
          ))}
        </div>
        <Button type="button" onClick={() => null}>
          More articles
        </Button>
      </div>
    </section>
  );
};

export default ArticleList;
