import React from "react";

import DefineBusiness from "@pages/Homepage/DefineBusiness";
import AboutUsSummary from "@pages/Homepage/AboutUsSummary";
import HowWeHelp from "@pages/Homepage/HowWeHelp";
import ProjectGallery from "@pages/Homepage/ProjectsGallery";
import ClientsSay from "@pages/Homepage/ClientsSay";

import Request from "@components/Request";

const Homepage = (): JSX.Element => (
  <>
    <DefineBusiness />
    <AboutUsSummary />
    <HowWeHelp />
    <ProjectGallery />
    <ClientsSay />
    <Request />
  </>
);

export default Homepage;
