import React, { useRef, useState } from "react";

import CloseButton from "@src/components/CloseButton";
import Select from "@src/components/Select";

import useClickOutside from "@src/hooks/useClickOutside";

import "./ReportForm.scss";

interface Props {
  handleClose: () => void;
}

const ReportForm = ({ handleClose }: Props): JSX.Element => {
  const options = [
    "Abuse",
    "Off topic",
    "Legal issue",
    "Offensive/Threatening",
    "Spam",
    "Other",
  ];
  const [value, setValue] = useState("");
  const ref = useRef(null);

  useClickOutside({ ref, callback: handleClose });

  return (
    <div className="report-form" ref={ref}>
      <div className="report-form__container" role="row">
        <CloseButton onClick={handleClose} className="report-form__close-btn" />
        <h4 className="report-form__title">Category</h4>
        <Select options={options} setValue={setValue} value={value} />
        <label htmlFor="reportEmail">
          Email (optional)
          <input
            type="text"
            id="reportEmail"
            placeholder="e.g. example@gmail.com"
          />
        </label>
      </div>
      <button type="button" className="report-form__report-btn">
        Report
      </button>
    </div>
  );
};

export default ReportForm;
