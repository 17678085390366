import React, { useState } from "react";

import Like from "@images/Article/like.svg";
import LikeFill from "@images/Article/like-fill.svg";
import Flag from "@images/Article/flag.svg";

import { CommentInterface } from "..";

import "./ArticleComment.scss";
import ReportForm from "../../ReportForm";

interface Props {
  comment: CommentInterface;
}

const ArticleComment = ({ comment }: Props): JSX.Element => {
  const [showText, setShowText] = useState(false);
  const [showReport, setShowReport] = useState(false);

  return (
    <div className="article-comment">
      <a href="https://www.youtube.com/" className="article-comment__user-img">
        <img
          src={comment.user.image}
          alt="medoxn user comment"
          title="medoxn user comment"
          height="83"
          width="83"
        />
      </a>
      <div className="article-comment__content-wrap">
        <div className="article-comment__header">
          <h3>{comment.user.name}</h3>
          <p className="article-comment__date">{comment.createdAt}</p>
        </div>
        <p
          className={`article-comment__text ${
            showText ? "article-comment-mobile" : ""
          }`}
        >
          {comment.text}
        </p>
        <button
          className="button-read-more"
          type="button"
          onClick={() => setShowText(!showText)}
        >
          {showText ? "Read less" : "Read more"}
        </button>
        <div className="article-comment__controls">
          <div>
            <button type="button" className="article-comment__like">
              <img
                src={comment.liked ? LikeFill : Like}
                alt="like"
                title="like"
                height="18"
                width="18"
              />
              <span
                className={`article-comment__like_span ${
                  comment.liked ? "article-comment__like_span_liked" : ""
                }`}
              >
                {comment.likes}
              </span>
            </button>
            <button type="button">Reply</button>
            <button type="button">Share</button>
          </div>
          <button type="button" onClick={() => setShowReport(!showReport)}>
            <img
              src={Flag}
              alt="flag"
              title="medoxn flag"
              height="16"
              width="12"
            />
          </button>
        </div>
        {showReport && <ReportForm handleClose={() => setShowReport(false)} />}
      </div>
    </div>
  );
};

export default ArticleComment;
