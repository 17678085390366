import { RefObject, useEffect } from "react";

interface Props {
  ref: RefObject<HTMLElement>;
  callback: () => void;
}

const useClickOutside = ({ ref, callback }: Props): void => {
  const handleClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export default useClickOutside;
