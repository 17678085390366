import React from "react";

import Article from "@images/Article/article-img.webp";

import quote from "@images/quote.svg";

import "./ArticleMain.scss";

const ArticleMain = (): JSX.Element => (
  <section className="article-main">
    <div className="article-main__container container">
      <div className="article-main__img-wrap">
        <img
          loading="lazy"
          src={Article}
          alt="article"
          title="medoxn article"
          height="861"
          width="715"
        />
      </div>
      <div className="article-main__content-wrap">
        <p className="article-main__text">
          Over the years, I have seen many dentists who are in business and
          struggling, more often than not as a result of their limited business
          background and/or struggling with personnel. There are options out
          there that many do not know exist and I have seen dentists who will
          sell their practice and go work for someone else. I have also seen
          dentist’s create a financial arrangement with a professional to run
          the business while they continue to do what they enjoy most… dentistry
          and most often, the practice thrives beyond the dentist’s expectations
          because they no longer have the weight of all the things they did not
          like to do on their shoulders. Usually other parts of the dentist’s
          life begin to thrive as well…their marriage, family ties and
          relationships and then all of a sudden they have what they did not
          dream was possible…extra time and a balanced life.
        </p>
        <h2 className="article-main__title">Heading 1</h2>
        <p className="article-main__text">
          Over the years, I have seen many dentists who are in business and
          struggling, more often than not as a result of their limited business
          background and/or struggling with personnel. There are options out
          there that many do not know exist and I have seen dentists who will
          sell their practice and go work for someone else. I have also seen
          dentist’s create a financial arrangement with a professional to run
          the business while they continue to do what they enjoy most… dentistry
          and most often, the practice thrives beyond the dentist’s expectations
          because they no longer have the weight of all the things they did not
          like to do on their shoulders. Usually other parts of the dentist’s
          life begin to thrive as well…their marriage, family ties and
          relationships and then all of a sudden they have what they did not
          dream was possible…extra time and a balanced life.
        </p>
        <img src={quote} alt="quote" title="qoute" height="112" width="153" />
        <p className="article-main__quote">
          “Growing is painful. Change is painful. But nothing is as painful as
          being stuck somewhere you don’t belong.”
        </p>
        <h2 className="article-main__title">Heading 2</h2>
        <p className="article-main__text">
          Over the years, I have seen many dentists who are in business and
          struggling, more often than not as a result of their limited business
          background and/or struggling with personnel. There are options out
          there that many do not know exist and I have seen dentists who will
          sell their practice and go work for someone else. I have also seen
          dentist’s create a financial arrangement with a professional to run
          the business while they continue to do what they enjoy most… dentistry
          and most often, the practice thrives beyond the dentist’s expectations
          because they no longer have the weight of all the things they did not
          like to do on their shoulders. Usually other parts of the dentist’s
          life begin to thrive as well…their marriage, family ties and
          relationships and then all of a sudden they have what they did not
          dream was possible…extra time and a balanced life.
        </p>
        <h2 className="article-main__title">Heading 3</h2>
        <p className="article-main__text">
          Over the years, I have seen many dentists who are in business and
          struggling, more often than not as a result of their limited business
          background and/or struggling with personnel. There are options out
          there that many do not know exist and I have seen dentists who will
          sell their practice and go work for someone else. I have also seen
          dentist’s create a financial arrangement with a professional to run
          the business while they continue to do what they enjoy most… dentistry
          and most often, the practice thrives beyond the dentist’s expectations
          because they no longer have the weight of all the things they did not
          like to do on their shoulders. Usually other parts of the dentist’s
          life begin to thrive as well…their marriage, family ties and
          relationships and then all of a sudden they have what they did not
          dream was possible…extra time and a balanced life.
        </p>
      </div>
    </div>
  </section>
);
export default ArticleMain;
