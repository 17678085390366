import React, { MouseEventHandler } from "react";

import "./Button.scss";

interface Props {
  onClick: MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  type: "submit" | "reset" | "button";
}

const Button = ({ onClick, children, type }: Props): JSX.Element => (
  // eslint-disable-next-line react/button-has-type
  <button onClick={onClick} type={type} className="custom-button">
    {children}
  </button>
);

export default Button;
