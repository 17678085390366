import React, { FunctionComponent } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Homepage from "@pages/Homepage";
import AboutUs from "@pages/AboutUs";
import Blog from "@pages/Blog";
import ContactUs from "@pages/ContactUs";
import Projects from "@pages/Projects";
import Project from "@pages/Project";
import Testimonials from "@pages/Testimonials";
import Article from "@pages/Article";

interface RoutesInterface {
  name: string;
  path: string;
  component: React.ComponentType<FunctionComponent>;
}

export const routes: RoutesInterface[] = [
  { name: "ABOUT US", path: "/about-us", component: AboutUs },
  { name: "PROJECTS", path: "/projects", component: Projects },
  { name: "TESTIMONIALS", path: "/testimonials", component: Testimonials },
  { name: "BLOG", path: "/blog", component: Blog },
  { name: "CONTACT US", path: "/contact-us", component: ContactUs },
];

const Routes = (): JSX.Element => (
  <Switch>
    <Route exact path="/" component={Homepage} />
    <Route exact path="/projects/:id" component={Project} />
    <Route exact path="/blog/:id" component={Article} />
    {routes.map((route) => (
      <Route
        key={route.path}
        exact
        path={route.path}
        component={route.component}
      />
    ))}
    <Redirect to="/" />
  </Switch>
);

export default Routes;
