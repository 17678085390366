import React, { useEffect, useState } from "react";

import ArticleItem from "@pages/Blog/ArticleItem";

import Article1 from "@images/Blog/article-1.webp";
import Article2 from "@images/Blog/article-2.webp";
import Article3 from "@images/Blog/article-3.webp";

import "./OtherArticles.scss";
import { useKeenSlider } from "keen-slider/react.es.js";
import Arrow from "@src/components/Arrow";
import arrayFromNumber from "@src/utils/arrayFromNumber";
import useWindowDimensions from "@src/hooks/useWindowDimension";

const OtherArticles = (): JSX.Element => {
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slides: {
      perView: 3,
      spacing: 0,
    },
    drag: false,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    breakpoints: {
      "(max-width: 1400px)": {
        slides: {
          perView: 3,
          spacing: 0,
        },
      },
      "(max-width: 1200px)": {
        slides: {
          perView: 2,
          spacing: 0,
        },
        drag: true,
      },
      "(max-width: 768px)": {
        slides: {
          perView: 1,
          spacing: 0,
        },
        drag: true,
      },
    },
  });

  const articleMock = [
    { id: 1, image: Article1 },
    { id: 2, image: Article2 },
    { id: 3, image: Article3 },
  ];

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (instanceRef.current) {
      const { perView } = instanceRef.current.options.slides as {
        perView: number;
      };
      setSlidesPerView(perView);
    }
  }, [width, instanceRef]);

  return (
    <div className="other-articles">
      <div className="other-articles__container">
        <h2>Other articles</h2>
        <div
          className="other-articles__article-wrap keen-slider"
          ref={sliderRef}
        >
          {articleMock.map((article) => (
            <div className="keen-slider__slide" key={article.id}>
              <ArticleItem article={article} />
            </div>
          ))}
        </div>
        <div className="other-articles__slider-control">
          {loaded && instanceRef && instanceRef.current && (
            <div className="dots">
              {arrayFromNumber(width < 768 ? 3 : 2).map((slide) => (
                <button
                  type="button"
                  key={slide}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(slide);
                  }}
                  aria-label="button"
                  className={`dot  ${currentSlide === slide ? " active" : ""}`}
                />
              ))}
            </div>
          )}
          {loaded && instanceRef.current && (
            <div className="associations__arrows">
              <Arrow
                properties={{
                  direction: "left",
                  disabled: currentSlide === 0,
                }}
                onClick={() => instanceRef.current?.prev()}
              />
              <Arrow
                properties={{
                  direction: "right",
                  disabled:
                    instanceRef &&
                    instanceRef.current &&
                    currentSlide ===
                      instanceRef.current.track.details.slides.length -
                        Math.floor(slidesPerView),
                }}
                onClick={() => instanceRef.current?.next()}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OtherArticles;
