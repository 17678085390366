import React from "react";

import Request from "@src/components/Request";

import Hero from "./Hero";
import PostList from "./ArticleList";

const Blog = (): JSX.Element => (
  <main>
    <Hero />
    <PostList />
    <Request />
  </main>
);

export default Blog;
