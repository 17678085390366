import React from "react";

import PlayButton from "@components/PlayButton";
import videoThumbnail from "@images/Homepage/video-thumbnail.webp";

import "./DefineBusiness.scss";

const DefineBusiness = (): JSX.Element => (
  <section className="define-business">
    <div className="hero-container">
      <div className="define-business__video">
        <img
          className="define-business__thumbnail"
          src={videoThumbnail}
          alt="thumbnail"
          title="video hospital dentist"
        />
        <PlayButton
          onClick={() => null}
          className="define-business__play_button"
        />
      </div>
      <div className="define-business__text-container container">
        <div className="define-business__text">
          <div className="define-business__title">
            <h1>SPACES THAT DEFINE YOUR BUSINESS</h1>
          </div>
          <div className="define-business__paragraph">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default DefineBusiness;
