import React from "react";

import Img from "@images/AboutUs/about-us-article.webp";
import PostMain from "../ArticleMain/index";

import "./Hero.scss";

const Hero = (): JSX.Element => (
  <section className="blog__hero blog-hero">
    <div className="blog-hero__container container">
      <h1 className="blog-hero__title">Our blog</h1>
      <div className="blog-hero__article hero-article">
        <PostMain id={0} />
        <img
          src={Img}
          alt="hospital"
          title="medoxn hospital"
          className="hero-article__img"
          loading="lazy"
          height="543"
          width="880"
        />
      </div>
    </div>
  </section>
);

export default Hero;
