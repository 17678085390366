import React, { useState } from "react";

import mockedTestimonials from "@pages/Testimonials/Cards/mockedTestimonials";

import { useAppSelector } from "@src/store/hooks";

import Button from "@components/Button";
import Card from "./Card";

import "./Cards.scss";

const Cards = (): JSX.Element => {
  const [testimonials, setTestimonials] = useState(mockedTestimonials);
  const { filter } = useAppSelector((state) => state.filter);

  const filteredTestimonials = testimonials.filter((testimonial) => {
    if (filter === "Video only") {
      return testimonial.media.type === "video";
    }
    if (filter === "Text only") {
      return testimonial.media.type === "image";
    }
    return true;
  });

  return (
    <section className="testimonials-cards">
      {filteredTestimonials.map(({ media, person }) => (
        <Card
          key={Math.floor(Math.random() * 999999)}
          media={media}
          person={person}
        />
      ))}
      <div className="testimonials-cards__button-container">
        <Button
          type="button"
          onClick={() =>
            setTestimonials((prevState) => [
              ...prevState,
              ...mockedTestimonials,
            ])
          }
        >
          More reviews
        </Button>
      </div>
    </section>
  );
};

export default Cards;
