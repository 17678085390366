import React, { useState } from "react";

import validateForm, { phoneMask } from "@src/utils/validateForm";
import { defaultFormData, FormDataType } from "@src/pages/ContactUs";

import Button from "@src/components/Button";
import "./Request.scss";

const Request = (): JSX.Element => {
  const [formData, setFormData] = useState(defaultFormData);
  const [errors, setErrors] = useState<Partial<FormDataType>>({});

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    validateForm(formData, setErrors);
  };

  return (
    <section className="about-us-request">
      <div className="about-us-request__container container">
        <div className="about-us-request__form-wrap">
          <form action="#" className="about-us-request__form request-form">
            <label
              htmlFor="name"
              className={`request-form__label ${
                errors?.name ? "request-form__label_error" : ""
              }`}
            >
              <div>
                Name*
                <span className="about-us-request__error">{errors?.name}</span>
              </div>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData((prev) => ({ ...prev, name: e.target.value }))
                }
                className="request-form__name"
                placeholder="Your Full Name"
              />
            </label>
            <label
              htmlFor="email"
              className={`request-form__label ${
                errors?.email ? "request-form__label_error" : ""
              }`}
            >
              <div>
                Email
                <span className="about-us-request__error">{errors?.email}</span>
              </div>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData((prev) => ({ ...prev, email: e.target.value }))
                }
                className="request-form__email"
                placeholder="e.g. example@gmail.com"
              />
            </label>
            <label
              htmlFor="phone"
              className={`request-form__label ${
                errors?.phone ? "request-form__label_error" : ""
              }`}
            >
              <div>
                Phone*
                <span className="about-us-request__error">{errors?.phone}</span>
              </div>
              <input
                type="text"
                id="phone"
                value={formData.phone}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFormData((prev) => ({
                    ...prev,
                    phone: phoneMask(e.target.value),
                  }));
                }}
                className="request-form__phone"
                placeholder="(000) 000-0000"
              />
            </label>
            <label
              htmlFor="message"
              className={`request-form__label ${
                errors?.message ? "request-form__label_error" : ""
              }`}
            >
              <div>
                Message
                <span className="about-us-request__error">
                  {errors?.message}
                </span>
              </div>
              <textarea
                id="message"
                value={formData.message}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setFormData((prev) => ({ ...prev, message: e.target.value }))
                }
                className="request-form__message"
                placeholder="What would you like to discuss?"
              />
            </label>
          </form>
          <Button type="submit" onClick={onSubmit}>
            Submit
          </Button>
        </div>
        <div className="about-us-request__title-wrap">
          <h2>Request Call Back</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Request;
