import React from "react";

import "./Hero.scss";

const Hero = (): JSX.Element => (
  <section className="article__hero article-hero">
    <div className="article-hero__container">
      <h1 className="article-hero__text">
        it’s time to take a look at your business - Are there things that you
        have put off that may be detrimental?
      </h1>
    </div>
  </section>
);

export default Hero;
