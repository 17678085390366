import React from "react";

import Request from "@components/Request";

import OtherProjects from "./OtherProjects";
import ProjectHero from "./ProjectHero";
import ProjectMain from "./ProjectMain";

const Project = (): JSX.Element => (
  <>
    <ProjectHero />
    <ProjectMain />
    <OtherProjects />
    <Request />
  </>
);

export default Project;
