import React from "react";
import { Link } from "react-router-dom";

import ViewBtn from "@images/Projects/buttons/view.svg";

import "./ProjectItem.scss";

interface Props {
  name: string;
  image: string;
  id: number;
}

const ProjectItem = (props: Props): JSX.Element => {
  const { name, image, id } = props;

  return (
    <div className="project-item">
      <Link to={`/projects/${id}`}>
        <img
          loading="lazy"
          src={image}
          alt="project-item"
          title="project medoxn"
          height="546"
          width="750"
        />
        <div className="project-item__name">{name}</div>
        <button type="button" className="project-item__button">
          <img
            src={ViewBtn}
            alt="view project button"
            title="view project button"
            height="30"
            width="46"
          />
        </button>
      </Link>
    </div>
  );
};

export default ProjectItem;
